.a-company-hero {
  .hero {
    &__text {
      margin-top: 16px;
      line-height: 22px;
    }
    &__qoute {
      font-style: italic;
      line-height: 22px;
      margin-top: 20px;
    }
  }
  ul {
    padding-left: 58px;

      li {
        position: relative;
        line-height: 22px;
        max-width: 958px;
        text-align: justify;
        &:first-child {
          margin-top: 10px;
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &::before {
          content: '';
          position: absolute;
          left: -17px;
          top: 13px;
          width: 4px;
          height: 4px;
          background-color: @color-main;
          border-radius: 50%;
        }
      }
  }
}
@media screen and (max-width: @screen-sm) {
  .a-company-hero {
    ul {
      padding-left: 20px;
    }
  }
}