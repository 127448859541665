@import "blocks/less/fonts.less";
@import "blocks/less/variables.less";
@import "blocks/less/mixins.less";
@import "blocks/less/functions.less";
@import "blocks/less/scafolding.less";
//main-page
@import "blocks/header/header";
@import "blocks/footer/footer";
@import "blocks/extra-header/extra-header";
@import "blocks/main-page/test/test";
@import "blocks/main-page/why/why";
@import "blocks/main-page/partners/partners";
@import "blocks/main-page/request/request";
//about
@import "blocks/about/about-hero/about-hero";
@import "blocks/about/diplomas/diplomas";
@import "blocks/about/a-links-section/a-links-section";
@import "blocks/about/a-partners/a-partners";
//services
@import "blocks/services/s-hero/s-hero";
@import "blocks/services/s-case";
//articles
@import "blocks/articles/articles";
//article
@import "blocks/article/article";
//reviews
@import "blocks/reviews/reviews/reviews";
@import "blocks/reviews/feedback/feedback";
//contacts
@import "blocks/contacts/contacts";
//strategy
@import "blocks/strategy/strategy-hero/strategy";
@import "blocks/strategy/strategy-cases/strategy-cases";
//sites
@import "blocks/sites/sites-hero/sites-hero";
@import "blocks/sites/sites-cases/sites-cases";
//selling
@import "blocks/selling/selling-hero/selling-hero";
@import "blocks/selling/selling-cases-s/selling-cases-s";
@import "blocks/selling/selling-cases-pr/selling-cases-pr";
//a-company
@import "blocks/a-company/a-comp-hero/a-comp-hero";
@import "blocks/a-company/a-comp-cases";
@import "blocks/a-company/a-comp-presale/a-comp-presale";
@import "blocks/a-company/a-comp-pers/a-comp-pers";
@import "blocks/a-company/a-comp-b/a-comp-b";
@import "blocks/a-company/a-comp-leaflets/a-comp-leaflets";
//corp-culture
@import "blocks/corp-culture/corp-cult-hero/corp-cult-hero";
@import "blocks/corp-culture/corp-cult-links/corp-cult-links";
@import "blocks/corp-culture/corp-cult-cases/corp-cult-cases";
//inn
@import "blocks/inn/inn.less";
//modal
@import "blocks/modal/modal.less";
.g-recaptcha > div,
.g-recaptcha iframe {
    // width: 342px !important;
    margin-bottom: 16px;
}
.re-wrap {
    position: relative;
    padding-bottom: 20px;
  }
  .re-error {
    display: none;
    position: absolute;
    bottom: 5px;
    left: 0;
    color: #ca0f0f;
  }