.corp-cult-cases {
  background-color: #f0f0f0;
  //overflow: hidden;
  &__title {
    text-align: center;
    margin-bottom: 40px;
    span {
      text-transform: none;
    }
  }
  .container {
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 824px;
      height: 42px;
      bottom: -42px;
      background-image: url(../img/header/header-shadow-left.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
    }
    &::before {
      left: -120px;
    }
    &::after {
      right: -120px;
      transform: scaleX(-1);
    }
    .img-wrapper {
      box-shadow: -1.2px 4.9px 10px 0 rgba(0, 0, 0, 0.3);
      &::after {
        display: none;
      }
    }
  }
  .s-case__item .wrap {
    padding-bottom: 45px;
  }
  &.s-case .custom-arrow {
    top: 41%;
  }
  .corp-cult-carousel-m {
    display: none;
  }
}
@media screen and (max-width: 1400px) {
  .corp-cult-cases {
    .container {
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
  }
}
@media screen and (max-width: @screen-xl) {
  .corp-cult-cases {
    //box-shadow: 0 5px 9px rgba(0,0,0,.26);
    //overflow: auto;
    .container {
      &::before,
      &::after {
        display: none;
      }
    }
    .corp-cult-carousel {
      display: none;
    }
    .corp-cult-carousel-m {
      display: block;
    }
  }
}
@media screen and (max-width: @screen-md) {
  .s-case {
    &__text {
      font-size: 14px;
    }
  }
  .corp-cult-cases .s-case__item .wrap {
    padding-bottom: 25px;
  }
}