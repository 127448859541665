.hero {
  background-color: #f0f0f0;
  //overflow: hidden;
  .container {
    position: relative;
    padding-top: 45px;
    padding-bottom: 95px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 824px;
      height: 42px;
      bottom: -42px;
      background-image: url(../img/header/header-shadow-left.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
    }
    &::before {
      left: -120px;
      @media screen and (max-width: 1430px) {
        left: -25px;
        width: 734px;
      }
    }
    &::after {
      right: -232px;
      transform: scaleX(-1);
      @media screen and (max-width: 1650px) {
        right: -120px;
      }
      @media screen and (max-width: 1430px) {
        right: 0;
        width: 734px;
      }
    }
  }
  &__main-title {
    color: #e35557;
    line-height: 1;
    display: inline-block;
    margin-right: 23px;
    font-weight: normal;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
    }
  }
  &__title {
    color: #434343;
    text-transform: none;
    font-weight: normal;
  }
  &__text {
    line-height: 28px;
    text-align: justify;
    span {
      color: #c04421;
    }
  }
}
.hero .container p.hero__text-nm {
  margin: 0;
}
.about-hero-top {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
}
.about-hero {
  &__title {
    display: inline-block;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
    }
  }
  &__woman {
    margin-top: 32px;
  }
  &__subtext {
    line-height: 24px;
    color: #363636;
    text-decoration: underline;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &__text {
    span {
      font-size: 24px;
    }
  }
  .about-hero-quote {
    position: relative;
    margin-left: -100px;
    &__text {
      position: absolute;
      left: 125px;
      top: 50px;
      text-align: center;
      width: 325px;
      color: #c14724;
      font-size: 18px;
      line-height: 24px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 350px;
      }
    }
  }
}
@media screen and (max-width: 1180px) {
  .hero {
    .container {
      box-shadow: 0 5px 9px rgba(0,0,0,.26);
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
@media screen and (max-width: @screen-lg) {
  .about-hero {
    &__woman {
      width: 336px;
      height: 232px;
      margin-top: 90px;
    }
  }
}
@media screen and (max-width: @screen-md) {
  .hero {
    .container {
      padding-bottom: 40px;
    }
  }
  .about-hero {
    &__woman {
      margin-top: 0;
      width: 268px;
      height: 185px;
    }
    .about-hero-quote {
      margin-left: 0;
      img {
        display: none;
      }
      &__text {
        position: relative;
        left: auto;
        top: auto;
        width: auto;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .hero {
    &__title {
      text-align: center;
    }
  }
  .about-hero {
    text-align: center;
  }
  .about-hero-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: @screen-sm) {
  .hero {
    &__text {
      line-height: 1.4;
    }
  }
}