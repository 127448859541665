//reset/normalize
* {
  box-sizing: border-box;
  outline: none;
}
*::before, *::after {
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, h6, p, ol, ul, li {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ul, ol {
  list-style: none;
}
button {
  outline: none;
  border: none;
}
img {
  border: none;
}
a {
  text-decoration: none;
  color: inherit;
}
[contenteditable=true]:empty:before{
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block; /* For Firefox */
}
select {
  appearance: none;
  &::-ms-expand { // для IE
    display: none;
  }
}
input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}



body {
  font-family: @pt-sans;
  font-size: 1.125rem;// 18px
  line-height: 1.56em;//28px
  overflow-x: hidden;
  color: #434343;
  background-color: #fff;
  position: relative;
  margin: 0;
  padding: 0;
  @media screen and (max-width: @screen-sm) {
    //font-size: 0.88rem;
    //line-height: 1.43em;
  }
}

h1 {
  font-size: 40px;
  line-height: 1em;
  //color: #fff;
  @media screen and (max-width: 1600px) {
    //font-size: 60px;
    //line-height: 60px;
  }
  @media screen and (max-width: @screen-md) {
    //font-size: 40px;
    //line-height: 40px;
  }
  @media screen and (max-width: @screen-sm) {
    font-size: 36px;
  }
}
h2 {
  font-size: 36px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: normal;
  @media screen and (max-width: 1600px) {
    //font-size: 40px;
  }
  @media screen and (max-width: @screen-md) {
    //font-size: 30px;
  }
  @media screen and (max-width: @screen-sm) {
    font-size: 30px !important;
  }
}
h3 {
  font-size: 30px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: normal;
  @media screen and (max-width: @screen-sm) {
    font-size: 24px;
  }
}
h4 {
  font-size: 24px;
  line-height: 1em;
  @media screen and (max-width: @screen-sm) {
    font-size: 22px;
  }
}
h5 {
  font-size: 22px;
  line-height: 24px;
  @media screen and (max-width: @screen-sm) {
    font-size: 20px;
  }
}

.container {
  //max-width: 1200px;
  //width: 100%;
  max-width: 1180px;
  width: 100%;
  margin: auto;
  padding: 0 30px;
  @media screen and (max-width: 1600px) {
    //width: auto;
    //padding: 0 20px;
  }
  @media screen and (max-width: 1200px) {
    //width: auto;
    padding: 0 20px;
  }
  @media screen and (max-width: 480px) {
    padding: 0 15px;
  }
}

main {
  position: relative;
  //overflow: hidden;
}
// Скрытые элементы
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
//.visually-hidden {
//  position: absolute !important;
//  height: 1px; width: 1px;
//  overflow: hidden;
//  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
//  clip: rect(1px, 1px, 1px, 1px);
//  clip-path: polygon(0px 0px, 0px 0px,0px 0px, 0px 0px);
//}

.apple-fix {
  width: 100%;
  position: fixed;
}
.no-scroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}
.img-wrapper {
  display: inline-block;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
//flex-wrapper - чтобы футер прилипал к низу
//.flex-wrapper {
//  display: flex;
//  flex-direction: column;
//  //height: 100%;
//  height: 100vh;
//  .header {
//    flex: 0 0 auto;
//  }
//  .main-content {
//    flex: 1 0 auto;
//  }
//  .foot {
//    flex: 0 0 auto;
//  }
//}
.clearfix {
  &::after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
}


//IE 11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  p, h1, h2, h3, h4, h5, h6 {
    width: 100%;
  }
}



/*
/////////////////
  ANIMATION
/////////////////
*/
.hover-link {
  position: relative;
  color: #000;
  .transition();
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: rgba(0,0,0,1);
    transition: all .2s linear;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &::before {
      left: -5px;
      right: -5px;
      height: 100%;
      background-color: rgba(0,0,0,0.8);
    }
  }
  &--red {
    &::before {
      background-color: rgba(193, 71, 36, 1);
    }
    &:hover {
      //color: #fff;
      &::before {
        background-color: rgba(193, 71, 36, 1);
      }
    }
  }
}
.hover-link-line {
  position: relative;
  width: auto;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #c24725;
    .transition();
  }
  &:hover {
    &::after {
      transform: scale(0);
    }
  }
  &:active,
  &:focus {
    &::after {
      transform: scale(0);
    }
  }
}
.hover-btn {
  position: relative;
  z-index: 1;
  &::before {
    position: absolute;
    content: '';
    width: 0%;
    height: 100%;
    background: #cf1416;
    top: 0;
    left: auto;
    right: 0;
    border-radius: 10px;
    z-index: -1;
    transition: all .3s cubic-bezier(1,0,0,1);
  }
  &:hover{
    color: white;
    box-shadow:none;
    &::before{
      position: absolute;
      content: '';
      width: 100%; height: 100%;
      background: #cf1416;
      top: 0; left: 0; right: 0;
      z-index: -1;
    }
  }
}


/*
/////////////////
  ARROWS
/////////////////
*/
//.owl-nav {
//  button {
//    &.disabled {
//      .custom-arrow {
//        border-color: #eeeeee;
//        cursor: auto;
//      }
//    }
//  }
//}
//.custom-arrow {
//  width: 60px;
//  height: 60px;
//  border-left: 4px solid #f5bdbe;
//  border-bottom: 4px solid #f5bdbe;
//  position: absolute;
//  top: 50%;
//  @media screen and (max-width: @screen-xl) {
//    width: 40px;
//    height: 40px;
//  }
//  &--prev {
//    transform: translateY(-50%) rotate(45deg);
//    left: -70px;
//    @media screen and (max-width: @screen-xl) {
//      left: 10px;
//    }
//  }
//  &--next {
//    transform: translateY(-50%) rotate(-135deg);
//    right: -70px;
//    @media screen and (max-width: @screen-xl) {
//      right: 10px;
//    }
//  }
//  &.custom-arrow--black {
//    border-color: #7a7a7a;
//    &.custom-arrow {
//      &--prev {
//        left: -50px;
//        @media screen and (max-width: 1250px) {
//          left: 10px;
//        }
//      }
//      &--next {
//        right: -50px;
//        @media screen and (max-width: 1250px) {
//          right: 10px;
//        }
//      }
//    }
//  }
//}
//.owl-carousel {
//  &--black-arrow {
//    .owl-nav {
//      button {
//        &.disabled {
//          .custom-arrow {
//            border-color: #c1c1c1;
//          }
//        }
//      }
//    }
//  }
//}

.owl-carousel {
  position: relative;
  .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    button.owl-prev,
    button.owl-next {
      position: absolute;
      top: 50%;
      .custom-arrow {
        width: 40px;
        height: 40px;
        border-left: 2px solid #f5bdbe;
        border-bottom: 2px solid #f5bdbe;
        display: inline-block;
        background-color: transparent;
        @media screen and (max-width: @screen-xl) {
          //width: 40px;
          //height: 40px;
        }
        @media screen and (max-width: @screen-sm) {
          width: 30px;
          height: 30px;
        }
      }
    }
    button {
      &.owl-prev {
          transform: translateY(-50%) rotate(45deg);
          left: -30px;
          @media screen and (max-width: @screen-xl) {
            left: 10px;
          }
          @media screen and (max-width: @screen-sm) {
            left: 5px;
          }
        }
      &.owl-next {
          transform: translateY(-50%) rotate(-135deg);
          right: -30px;
          @media screen and (max-width: @screen-xl) {
            right: 10px;
          }
          @media screen and (max-width: @screen-sm) {
            right: 5px;
          }
        }
      &.disabled {
        cursor: auto;
        .custom-arrow {
          border-color: #eeeeee;
          cursor: auto;
        }
      }
    }
  }
  &--black-arrow {
    .owl-nav {
      button.owl-prev,
      button.owl-next {
        .custom-arrow {
          border-color: #7a7a7a;
        }
        &.disabled {
          .custom-arrow {
            border-color: #c1c1c1;
          }
        }
      }
      button.owl-prev {
        left: -30px;
        @media screen and (max-width: 1250px) {
          left: 10px;
        }
        @media screen and (max-width: @screen-sm) {
          left: 5px;
        }
      }
      button.owl-next {
        right: -30px;
        @media screen and (max-width: 1250px) {
          right: 10px;
        }
        @media screen and (max-width: @screen-sm) {
          right: 5px;
        }
      }
    }
    &.custom-arrow {
      &--prev {
        left: -30px;
        @media screen and (max-width: 1250px) {
          left: 10px;
        }
      }
      &--next {
        right: -30px;
        @media screen and (max-width: 1250px) {
          right: 10px;
        }
      }
    }
  }
}
.s-case .owl-carousel .owl-nav,
.s-case .owl-carousel .owl-nav button.owl-next,
.s-case .owl-carousel .owl-nav button.owl-prev {
  top: 40%;
}



//---------------------------
//  BREADCRUMBS
//---------------------------
.breadcrumbs {
  display: flex;
  &__item {
    position: relative;
    &:not(:last-child) {
      &::after {
        content: '/';
        position: relative;
        display: inline-block;
        font-size: 16px;
        margin: 0 3px;
      }
    }
    &.active {
      .breadcrumbs__link {
        pointer-events: none;
        color: #848484;
        &::after {
          display: none;
        }
      }
      &::after {
        display: none;
      }
    }
  }
  &__link {
    font-size: 16px;
    line-height: 24px;
    color: #c14624;
    .transition();
    position: relative;
    &:hover {
      color: #848484;
    }
  }
}

//---------------------------
//  TEXTUNDERLINE
//---------------------------
.textunderline {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    height: 1px;
    background-color: #c14624;
    .transition();
  }
  &:hover {
    &::after {
      background-color: rgba(255,255,255,0);
    }
  }
}

.btn {
  font-family: @pt-sans;
  font-size: 16.5px;
  line-height: 35px;
  text-decoration: none;
  text-align: center;
  outline: none;
  display: inline-block;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgba(228, 85, 86, 1);
  padding: 0 38px 0 53px;
  position: relative;
  .transition();
  overflow: hidden;
  border: 1px solid rgba(228, 85, 86, 1);
  &:hover {
    background-color: rgba(255,255,255,0);
    color: rgba(228, 85, 86, 1);
  }
}

//----------------------------
//  HAMBURGER
//----------------------------
.hamburger {
  width: 24px;
  height: 18px;
  position: relative;
  margin: auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 8;
  display: none;
  @media screen and (max-width: @screen-md) {
    display: block;
    //position: absolute;
    position: fixed;
    right: 20px;
    top: 30px;
  }
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    box-shadow: 0px 3px 8px 0px rgba(50, 50, 50, 0.75);
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
    }
    &:nth-child(4) {
      top: 16px;
    }
  }
  &.open {
    span {
      background-color: #fff;
      &:nth-child(1) {
        top: 18px;
        width: 0;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
      }
    }
  }
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 5;
  display: none;
  //display: block;
}

/*
/////////////////////
      FORM
////////////////////
*/
input, textarea {
  border: none;
  outline: none;
  border-radius: 0;
  width: 100%;
  background-color: #fff;
  font-family: @pt-sans;
  font-size: 18px;
  line-height: 24px;
  //color: @color-title;
  padding-left: 20px;
  padding-right: 10px;
  margin-bottom: 16px;
}
input {
  height: 44px;
}
textarea {
  height: 120px;
  resize: none;
  padding-top: 10px;
  margin-top: 3px;
}
::-webkit-input-placeholder {
  color: rgba(79, 93, 109, 0.73);
  //font-family: @proxima-regular;
}
::-moz-placeholder {
  color: rgba(79, 93, 109, 0.73);
  //font-family: @proxima-regular;
}
:-moz-placeholder {
  color: rgba(79, 93, 109, 0.73);
  //font-family: @proxima-regular;
}
:-ms-input-placeholder {
  color: rgba(79, 93, 109, 0.73);
  //font-family: @proxima-regular;
}

/*
/////////////////////
      CHECKBOX
////////////////////
*/

/* Скрываем реальный чекбокс */
.checkbox {
  display: none;
}
/* Задаем внешний вид для нашего кастомного чекбокса. Все обязательные свойства прокомментированы, остальные же свойства меняйте по вашему усмотрению */
.checkbox-custom {
  position: relative;      /* Обязательно задаем, чтобы мы могли абсолютным образом позиционировать псевдоэлемент внютри нашего кастомного чекбокса */
  width: 20px;             /* Обязательно задаем ширину */
  height: 20px;            /* Обязательно задаем высоту */
  //border: 2px solid #ccc;
  border-radius: 3px;
  background-color: #ededed;
}
/* Кастомный чекбокс и лейбл центрируем по вертикали. Если вам это не требуется, то вы можете убрать свойство vertical-align: middle из данного правила, но свойство display: inline-block обязательно должно быть */
.checkbox-custom,
.label {
  display: inline-block;
  vertical-align: middle;
}
/* Если реальный чекбокс у нас отмечен, то тогда добавляем данный признак и к нашему кастомному чекбоксу  */
.checkbox:checked+.checkbox-custom {
  background-color: transparent;
  border: 1px solid #ededed;
}
.checkbox:checked + .checkbox-custom::before {
  content: "";             /* Добавляем наш псевдоэлемент */
  display: block;			 /* Делаем его блочным элементом */
  position: absolute;      /* Позиционируем его абсолютным образом */
  /* Задаем расстояние от верхней, правой, нижней и левой границы */
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  //background: @color-success;     /* Добавляем фон. Если требуется, можете поставить сюда картинку в виде "галочки", которая будет символизировать, что чекбокс отмечен */
  border-radius: 2px;
}

/*
////////////////
    SOC-BLOCK
////////////////
*/
.share-soc {
  display: flex;
  align-items: flex-start;
  &__item {
    width: 25px;
    height: 25px;
    &:not(:last-child) {
      margin-right: 5px;
    }
    &:nth-child(1) {
      a {
        background-position-x: 0;
      }
    }
    &:nth-child(2) {
      a {
        background-position-x: 115px;
      }
    }
    &:nth-child(3) {
      a {
        background-position-x: 85px;
      }
    }
    &:nth-child(4) {
      a {
        background-position-x: 55px;
      }
    }
    &:nth-child(5) {
      a {
        background-position-x: 25px;
      }
    }
  }
  &__link {
    width: 25px;
    height: 25px;
    background-image: url(../img/test/test-share.png);
    display: inline-block;
  }
}
.ya-share2__container_size_m li.ya-share2__item {
  margin-left: 7px;
  margin-bottom: 10px;
}

//logo-block
.logo-block {
  &__title {
    font-size: 40px;
    line-height: 1;
    color: #e35557;
  }
  &__subtitle {
    font-size: 17px;
    line-height: 1.32;
    color: #636363;
  }
}
//contacts-block
.contacts-block {
  display: flex;
  flex-direction: column;
  &__tel {
    font-size: 26px;
    line-height: 1;
    color: #c14624;
    margin-bottom: 10px;
    white-space: nowrap;
    .transition();
    &:hover {
      color: #404040;
    }
  }
  &__viber {
    height: 30px;
    margin-bottom: 2px;
  }
  &__wa {
    display: flex;
    //align-items: center;
    font-family: @helvetica;
    font-size: 12px;
    line-height: 14.8px;
    color: #808080;
    margin-top: 2px;
    margin-left: 28px;
  }
  &__wa-text {
    align-self: center;
  }
  //&__viber,
  //&__wa {
  //  .transition();
  //  &:hover {
  //    transform: scale(1.2);
  //  }
  //}
  &__email,
  &__skype {
    padding-left: 26px;
    position: relative;
    font-size: 17px;
    line-height: 30px;
    color: #404040;
    .transition();
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 53%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:hover {
      color: #c14727;
    }
  }
  &__email {
    &::before {
      width: 19px;
      height: 14px;
      background-image: url(../img/header/email-icon.png);
    }
  }
  &__skype {
    &::before {
      width: 20px;
      height: 20px;
      background-image: url(../img/header/skype-icon.png);
    }
  }
  &__review {
    display: inline-block;
    //color: #c14727;
    color: #fff;
    position: relative;
    align-self: flex-start;
    font-size: 16.5px;
    //line-height: 1;
    // line-height: 37px;
    line-height: 1.2;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
    //padding: 5px;
    border: none;
    border-radius: 0;
    color: #e45556;
    background-color: transparent;
    @media screen and (max-width: 600px) {
      border-radius: 10px;
      color: #fff;
      line-height: 37px;
      background-color: #e45556;
      padding: 0 38px 0 53px;
      border: 1px solid #e45556;
      &::after {
        display: none;
      }
    }
    &::before {
      left: 5px;
      right: 5px;
      bottom: 3px;
    }
    &:hover {
      //color: #fff;
      //&::before {
      //  left: 0;
      //  right: 0;
      //  bottom: 0;
      //}
    }
  }
  &__btn {
    margin: 8px 0 15px;
  }
  .wrap {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.accent {
  color: @color-main;
}