.articles {
  .container {
    padding-bottom: 55px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
  }
  &__item {
    width: 344px;
    height: 589px;
    border: solid 1px #c5573c;
    position: relative;
    background-color: #fff;
    margin-bottom: 32px;
    &::after {
      content: "";
      position: absolute;
      left: -30px;
      right: 0;
      bottom: -42px;
      width: 387px;
      height: 42px;
      background: transparent url(../img/a-partners/a-partners-shadow.png) 50%/cover no-repeat;
    }
    &:nth-child(3n+2) {
      margin-left: 43px;
      margin-right: 43px;
    }
    .img-wrapper {
      width: 100%;
      height: 335px;
    }
  }
  &__content {
    overflow: hidden;
    height: 212px;
    padding: 5px 15px 20px 19px;
    text-align: right;
  }
  &__title {
    font-size: 20px;
    line-height: 1.2;
    color: #c14624;
    text-decoration: underline;
    .transition();
    margin-bottom: 10px;
    display: inline-block;
    text-align: left;
    overflow: hidden;
    max-height: 77px;
    &:hover {
      text-decoration: none;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 18px;
    color: #404040;
    text-align: justify;
    height: 145px;
    overflow: hidden;
  }
  &__more {
    color: #c14624;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    font-style: italic;
    //left: 100%;
    //transform: translateX(-50%);
    float: right;
    margin-right: 15px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #c14624;
    }
    &:hover {
      &::after {
        background-color: rgba(255,255,255,0);
      }
    }
  }
  &__all {
    position: relative;
    color: #c14624;
    font-style: italic;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 18px;
    line-height: 1;
    display: inline-block;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #c14624;
      .transition();
    }
    &:hover {
      &::after {
        background-color: rgba(255,255,255,0);
      }
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .articles {
    &__item {
      &:nth-child(3n+2) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    &__list {
      .articles__item {
        margin: 22px 10px 0 10px;
        //margin: 22px 20px 0 20px;
      }
    }
  }
}
@media screen and (max-width: @screen-md) {
  .articles {
    &__list {
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      .articles__item {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .articles {
    &__list {
      .articles__item {
        width: 290px;
        &::after {
          width: 309px;
          height: 33px;
          bottom: -33px;
        }
      }
    }
  }
}