.feedback {
  background-color: #f0f0f0;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 27px;
  .feedback-block {
    display: flex;
    justify-content: space-between;
  }
  .form-feedback {
    flex-grow: 1;
    margin-left: 55px;
    margin-top: 30px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: -100px;
      top: 75px;
      background: transparent url(../img/icons/note-icon--white.png) center/cover no-repeat;
      width: 82px;
      height: 75px;
    }
    input,
    textarea {
      background-color: rgba(255,255,255,0.8);
    }
    input {
      height: 50px;
    }
    textarea {
      height: 176px;
      margin-bottom: 10px;
    }
  }
  &__title {
    text-transform: none;
    font-size: 32.5px;
    line-height: 65px;
    margin-bottom: 10px;
  }

  &__rate-text {
    color: rgba(47, 47, 47, 0.73);
    display: flex;
    align-items: flex-start;
    padding-left: 12px;
  }
  &__rate {
    margin-left: 5px;
    margin-top: -2px;
  }
  &__btn {
    //position: relative;
    //left: 100%;
    //transform: translateX(-100%);

    //float: right;

    display: block;
    margin-left: auto;

    font-size: 18px;
    line-height: 48px;
    padding: 0 62px 0 73px;
    margin-bottom: 20px;
  }
  .img-wrapper {
    width: 311px;
    height: 473px;
    align-self: flex-end;
    flex-shrink: 0;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: @screen-lg) {
  .feedback {
    .form-feedback {
      margin-bottom: 15px;
    }
    &__btn {
      margin-top: 10px;
    }
  }
}
@media screen and (max-width: 1030px) {
  .feedback {
    .wrap {
      flex-direction: column;
    }
    &__btn {
      margin-left: 0;
    }
  }
}
@media screen and (max-width: @screen-md) {
  .feedback {
    .img-wrapper {
      display: none;
    }
    .form-feedback {
      margin-left: 0;
      margin-top: 0;
      &::before {
        display: none;
      }
    }
    &__btn {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .feedback {
    &__title {
      line-height: 1.3;
    }
    &__rate-text {
      flex-direction: column;
      align-items: center;
      padding-left: 0;
    }
    &__rate {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}