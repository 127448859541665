.strategy-hero {
  .container {
    padding-bottom: 75px;
  }
  &.hero {
    .hero {
      &__qoute {
        margin: 0;
        margin-top: 25px;
        font-style: italic;
      }
      &__text {
        margin-top: 25px;
      }
      &__text-nm {
        margin-top: 0;
      }
    }
  }
}