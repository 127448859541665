.footer {
  position: relative;
  background-color: #f0f0f0;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 870px;
    //width: 886px;
    background: transparent url(../img/footer/footer-bg.png) center/cover no-repeat;
    background-position-x: left;
  }
  .container {
    position: relative;
    padding-top: 80px;
    padding-bottom: 20px;
    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      background: transparent url(../img/footer/footer-shamp.png) center/cover no-repeat;
      width: 438px;
      height: 280px;
    }
  }
  .footer-logo-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .logo-block {
    display: inline-block;
  }
  .contacts-block {
    margin-left: 40px;
    margin-right: 45px;
    margin-top: 10px;
  }
  .footer-shamp-descr {
    margin-top: 10px;
    font-size: 24px;
    line-height: 36px;
    color: #c24725;
    width: 345px;
    &__text {
      &:nth-child(2) {
        text-align: right;
      }
      &:nth-child(3) {
        text-align: right;
        padding-right: 70px;
      }
    }
  }
  &__dev {
    margin-top: 70px;
    font-size: 14px;
    a {
      color: #c24927;
      // text-decoration: underline;
      // &:hover {
      //   text-decoration: none;
      // }
    }
  }
}
.footer-block {
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.footer {
  .ya-share2__list {
    display: flex;
    margin-bottom: 2px;
  }
  .ya-share2__container_size_m li.ya-share2__item {
    // margin: 5px 4px 5px 0;
    margin: 0 4px;
    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 1700px) {
  .footer {
    &::before {
      width: 725px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .footer {
    &::before {
      width: 650px;
    }
  }
}
@media screen and (max-width: 1370px) {//1366
  .footer {
    &::before {
      width: 590px;
    }
  }
}
@media screen and (max-width: @screen-xl) {
  .footer {
    &::before {
      width: 500px;
    }
    .container {
      padding-top: 40px;
      &::before {
        width: 306px;
        height: 196px;
      }
    }
  }
}
@media screen and (max-width: @screen-lg) {
  .footer {
    .footer-shamp-descr {
      width: auto;
      line-height: 1.2;
      font-size: 20px;
      text-align: right;
      &__text {
        display: inline;
        &:nth-child(3) {
          padding-right: 0;
          text-align: left;
        }
      }
    }
  }
}
@media screen and (max-width: @screen-md) {
  .footer {
    &::before {
      width: 100%;
    }
    .container {
      // padding-bottom: 40px;
      &::before {
        display: none;
      }
    }
    .footer-shamp-descr {
      display: none;
    }
    .footer-block {
      justify-content: space-around;
    }
    .contacts-block {
      margin-right: 0;
    }
    &__dev {
      text-align: center;
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .footer {
    .footer-block {
      flex-direction: column;
      align-items: center;
    }
    .logo-block {
      margin-bottom: 20px;
    }
    .share-soc {
      justify-content: center;
    }
    .contacts-block {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}