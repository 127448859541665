.selling-hero {
  .container {
    padding-bottom: 60px;
  }
  &.hero {
    .hero {
      &__text {
        margin-top: 24px;
      }
    }
  }
}