/* pt-sans-regular - latin_cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.eot');
  /* IE9 Compat Modes */
  src: local('PT Sans'), local('PTSans-Regular'), url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.svg#PTSans') format('svg');
  /* Legacy iOS */
}
/*  HelveticaNeueCyr-Roman - cyrillic */
@font-face {
  font-family: 'HelveticaNeueCyr-Roman';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/helvetica/HelveticaRegular.eot');
  /* IE9 Compat Modes */
  src: local('HelveticaRegular'), local('HelveticaNeueCyr-Roman'), url('../fonts/helvetica/HelveticaRegular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/helvetica/HelveticaRegular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/helvetica/HelveticaRegular.woff') format('woff'), /* Modern Browsers */ url('../fonts/helvetica/HelveticaRegular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/helvetica/HelveticaRegular.svg#HelveticaNeueCyr-Roman') format('svg');
  /* Legacy iOS */
}
/*  HeliosCondLightNormal - cyrillic */
@font-face {
  font-family: 'HeliosCondLightNormal';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/helios/HeliosCondLightNormal.eot');
  /* IE9 Compat Modes */
  src: local('HeliosCondLightNormal'), local('HeliosCondLightNormal'), url('../fonts/helios/HeliosCondLightNormal.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/helios/HeliosCondLightNormal.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/helios/HeliosCondLightNormal.woff') format('woff'), /* Modern Browsers */ url('../fonts/helios/HeliosCondLightNormal.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/helios/HeliosCondLightNormal.svg#HeliosCondLightNormal') format('svg');
  /* Legacy iOS */
}
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
* {
  box-sizing: border-box;
  outline: none;
}
*::before,
*::after {
  box-sizing: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ul,
ol {
  list-style: none;
}
button {
  outline: none;
  border: none;
}
img {
  border: none;
}
a {
  text-decoration: none;
  color: inherit;
}
[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block;
  /* For Firefox */
}
select {
  appearance: none;
}
select::-ms-expand {
  display: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
body {
  font-family: "PT Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 1.56em;
  overflow-x: hidden;
  color: #434343;
  background-color: #fff;
  position: relative;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 480px) {
}
h1 {
  font-size: 40px;
  line-height: 1em;
}
@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 36px;
  }
}
h2 {
  font-size: 36px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: normal;
}
@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
  h2 {
    font-size: 30px !important;
  }
}
h3 {
  font-size: 30px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: normal;
}
@media screen and (max-width: 480px) {
  h3 {
    font-size: 24px;
  }
}
h4 {
  font-size: 24px;
  line-height: 1em;
}
@media screen and (max-width: 480px) {
  h4 {
    font-size: 22px;
  }
}
h5 {
  font-size: 22px;
  line-height: 24px;
}
@media screen and (max-width: 480px) {
  h5 {
    font-size: 20px;
  }
}
.container {
  max-width: 1180px;
  width: 100%;
  margin: auto;
  padding: 0 30px;
}
@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 1200px) {
  .container {
    padding: 0 20px;
  }
}
@media screen and (max-width: 480px) {
  .container {
    padding: 0 15px;
  }
}
main {
  position: relative;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
.apple-fix {
  width: 100%;
  position: fixed;
}
.no-scroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}
.img-wrapper {
  display: inline-block;
}
.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
  }
}
/*
/////////////////
  ANIMATION
/////////////////
*/
.hover-link {
  position: relative;
  color: #000;
  transition: all 0.3s linear;
  z-index: 1;
}
.hover-link::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #000000;
  transition: all .2s linear;
  z-index: -1;
}
.hover-link:hover {
  color: #fff;
}
.hover-link:hover::before {
  left: -5px;
  right: -5px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.hover-link--red::before {
  background-color: #c14724;
}
.hover-link--red:hover::before {
  background-color: #c14724;
}
.hover-link-line {
  position: relative;
  width: auto;
}
.hover-link-line::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #c24725;
  transition: all 0.3s linear;
}
.hover-link-line:hover::after {
  transform: scale(0);
}
.hover-link-line:active::after,
.hover-link-line:focus::after {
  transform: scale(0);
}
.hover-btn {
  position: relative;
  z-index: 1;
}
.hover-btn::before {
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  background: #cf1416;
  top: 0;
  left: auto;
  right: 0;
  border-radius: 10px;
  z-index: -1;
  transition: all 0.3s cubic-bezier(1, 0, 0, 1);
}
.hover-btn:hover {
  color: white;
  box-shadow: none;
}
.hover-btn:hover::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #cf1416;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
/*
/////////////////
  ARROWS
/////////////////
*/
.owl-carousel {
  position: relative;
}
.owl-carousel .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
}
.owl-carousel .owl-nav button.owl-prev .custom-arrow,
.owl-carousel .owl-nav button.owl-next .custom-arrow {
  width: 40px;
  height: 40px;
  border-left: 2px solid #f5bdbe;
  border-bottom: 2px solid #f5bdbe;
  display: inline-block;
  background-color: transparent;
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 480px) {
  .owl-carousel .owl-nav button.owl-prev .custom-arrow,
  .owl-carousel .owl-nav button.owl-next .custom-arrow {
    width: 30px;
    height: 30px;
  }
}
.owl-carousel .owl-nav button.owl-prev {
  transform: translateY(-50%) rotate(45deg);
  left: -30px;
}
@media screen and (max-width: 1200px) {
  .owl-carousel .owl-nav button.owl-prev {
    left: 10px;
  }
}
@media screen and (max-width: 480px) {
  .owl-carousel .owl-nav button.owl-prev {
    left: 5px;
  }
}
.owl-carousel .owl-nav button.owl-next {
  transform: translateY(-50%) rotate(-135deg);
  right: -30px;
}
@media screen and (max-width: 1200px) {
  .owl-carousel .owl-nav button.owl-next {
    right: 10px;
  }
}
@media screen and (max-width: 480px) {
  .owl-carousel .owl-nav button.owl-next {
    right: 5px;
  }
}
.owl-carousel .owl-nav button.disabled {
  cursor: auto;
}
.owl-carousel .owl-nav button.disabled .custom-arrow {
  border-color: #eeeeee;
  cursor: auto;
}
.owl-carousel--black-arrow .owl-nav button.owl-prev .custom-arrow,
.owl-carousel--black-arrow .owl-nav button.owl-next .custom-arrow {
  border-color: #7a7a7a;
}
.owl-carousel--black-arrow .owl-nav button.owl-prev.disabled .custom-arrow,
.owl-carousel--black-arrow .owl-nav button.owl-next.disabled .custom-arrow {
  border-color: #c1c1c1;
}
.owl-carousel--black-arrow .owl-nav button.owl-prev {
  left: -30px;
}
@media screen and (max-width: 1250px) {
  .owl-carousel--black-arrow .owl-nav button.owl-prev {
    left: 10px;
  }
}
@media screen and (max-width: 480px) {
  .owl-carousel--black-arrow .owl-nav button.owl-prev {
    left: 5px;
  }
}
.owl-carousel--black-arrow .owl-nav button.owl-next {
  right: -30px;
}
@media screen and (max-width: 1250px) {
  .owl-carousel--black-arrow .owl-nav button.owl-next {
    right: 10px;
  }
}
@media screen and (max-width: 480px) {
  .owl-carousel--black-arrow .owl-nav button.owl-next {
    right: 5px;
  }
}
.owl-carousel--black-arrow.custom-arrow--prev {
  left: -30px;
}
@media screen and (max-width: 1250px) {
  .owl-carousel--black-arrow.custom-arrow--prev {
    left: 10px;
  }
}
.owl-carousel--black-arrow.custom-arrow--next {
  right: -30px;
}
@media screen and (max-width: 1250px) {
  .owl-carousel--black-arrow.custom-arrow--next {
    right: 10px;
  }
}
.s-case .owl-carousel .owl-nav,
.s-case .owl-carousel .owl-nav button.owl-next,
.s-case .owl-carousel .owl-nav button.owl-prev {
  top: 40%;
}
.breadcrumbs {
  display: flex;
}
.breadcrumbs__item {
  position: relative;
}
.breadcrumbs__item:not(:last-child)::after {
  content: '/';
  position: relative;
  display: inline-block;
  font-size: 16px;
  margin: 0 3px;
}
.breadcrumbs__item.active .breadcrumbs__link {
  pointer-events: none;
  color: #848484;
}
.breadcrumbs__item.active .breadcrumbs__link::after {
  display: none;
}
.breadcrumbs__item.active::after {
  display: none;
}
.breadcrumbs__link {
  font-size: 16px;
  line-height: 24px;
  color: #c14624;
  transition: all 0.3s linear;
  position: relative;
}
.breadcrumbs__link:hover {
  color: #848484;
}
.textunderline {
  position: relative;
}
.textunderline::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;
  height: 1px;
  background-color: #c14624;
  transition: all 0.3s linear;
}
.textunderline:hover::after {
  background-color: rgba(255, 255, 255, 0);
}
.btn {
  font-family: "PT Sans", sans-serif;
  font-size: 16.5px;
  line-height: 35px;
  text-decoration: none;
  text-align: center;
  outline: none;
  display: inline-block;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  background-color: #e45556;
  padding: 0 38px 0 53px;
  position: relative;
  transition: all 0.3s linear;
  overflow: hidden;
  border: 1px solid #e45556;
}
.btn:hover {
  background-color: rgba(255, 255, 255, 0);
  color: #e45556;
}
.hamburger {
  width: 24px;
  height: 18px;
  position: relative;
  margin: auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 8;
  display: none;
}
@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
    position: fixed;
    right: 20px;
    top: 30px;
  }
}
.hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  box-shadow: 0px 3px 8px 0px rgba(50, 50, 50, 0.75);
}
.hamburger span:nth-child(1) {
  top: 0;
}
.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  top: 8px;
}
.hamburger span:nth-child(4) {
  top: 16px;
}
.hamburger.open span {
  background-color: #fff;
}
.hamburger.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}
.hamburger.open span:nth-child(2) {
  transform: rotate(45deg);
}
.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
}
.hamburger.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
  display: none;
}
/*
/////////////////////
      FORM
////////////////////
*/
input,
textarea {
  border: none;
  outline: none;
  border-radius: 0;
  width: 100%;
  background-color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding-left: 20px;
  padding-right: 10px;
  margin-bottom: 16px;
}
input {
  height: 44px;
}
textarea {
  height: 120px;
  resize: none;
  padding-top: 10px;
  margin-top: 3px;
}
::-webkit-input-placeholder {
  color: rgba(79, 93, 109, 0.73);
}
::-moz-placeholder {
  color: rgba(79, 93, 109, 0.73);
}
:-moz-placeholder {
  color: rgba(79, 93, 109, 0.73);
}
:-ms-input-placeholder {
  color: rgba(79, 93, 109, 0.73);
}
/*
/////////////////////
      CHECKBOX
////////////////////
*/
/* Скрываем реальный чекбокс */
.checkbox {
  display: none;
}
/* Задаем внешний вид для нашего кастомного чекбокса. Все обязательные свойства прокомментированы, остальные же свойства меняйте по вашему усмотрению */
.checkbox-custom {
  position: relative;
  /* Обязательно задаем, чтобы мы могли абсолютным образом позиционировать псевдоэлемент внютри нашего кастомного чекбокса */
  width: 20px;
  /* Обязательно задаем ширину */
  height: 20px;
  /* Обязательно задаем высоту */
  border-radius: 3px;
  background-color: #ededed;
}
/* Кастомный чекбокс и лейбл центрируем по вертикали. Если вам это не требуется, то вы можете убрать свойство vertical-align: middle из данного правила, но свойство display: inline-block обязательно должно быть */
.checkbox-custom,
.label {
  display: inline-block;
  vertical-align: middle;
}
/* Если реальный чекбокс у нас отмечен, то тогда добавляем данный признак и к нашему кастомному чекбоксу  */
.checkbox:checked + .checkbox-custom {
  background-color: transparent;
  border: 1px solid #ededed;
}
.checkbox:checked + .checkbox-custom::before {
  content: "";
  /* Добавляем наш псевдоэлемент */
  display: block;
  /* Делаем его блочным элементом */
  position: absolute;
  /* Позиционируем его абсолютным образом */
  /* Задаем расстояние от верхней, правой, нижней и левой границы */
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  border-radius: 2px;
}
/*
////////////////
    SOC-BLOCK
////////////////
*/
.share-soc {
  display: flex;
  align-items: flex-start;
}
.share-soc__item {
  width: 25px;
  height: 25px;
}
.share-soc__item:not(:last-child) {
  margin-right: 5px;
}
.share-soc__item:nth-child(1) a {
  background-position-x: 0;
}
.share-soc__item:nth-child(2) a {
  background-position-x: 115px;
}
.share-soc__item:nth-child(3) a {
  background-position-x: 85px;
}
.share-soc__item:nth-child(4) a {
  background-position-x: 55px;
}
.share-soc__item:nth-child(5) a {
  background-position-x: 25px;
}
.share-soc__link {
  width: 25px;
  height: 25px;
  background-image: url(../img/test/test-share.png);
  display: inline-block;
}
.ya-share2__container_size_m li.ya-share2__item {
  margin-left: 7px;
  margin-bottom: 10px;
}
.logo-block__title {
  font-size: 40px;
  line-height: 1;
  color: #e35557;
}
.logo-block__subtitle {
  font-size: 17px;
  line-height: 1.32;
  color: #636363;
}
.contacts-block {
  display: flex;
  flex-direction: column;
}
.contacts-block__tel {
  font-size: 26px;
  line-height: 1;
  color: #c14624;
  margin-bottom: 10px;
  white-space: nowrap;
  transition: all 0.3s linear;
}
.contacts-block__tel:hover {
  color: #404040;
}
.contacts-block__viber {
  height: 30px;
  margin-bottom: 2px;
}
.contacts-block__wa {
  display: flex;
  font-family: "HelveticaNeueCyr-Roman", sans-serif;
  font-size: 12px;
  line-height: 14.8px;
  color: #808080;
  margin-top: 2px;
  margin-left: 28px;
}
.contacts-block__wa-text {
  align-self: center;
}
.contacts-block__email,
.contacts-block__skype {
  padding-left: 26px;
  position: relative;
  font-size: 17px;
  line-height: 30px;
  color: #404040;
  transition: all 0.3s linear;
}
.contacts-block__email::before,
.contacts-block__skype::before {
  content: '';
  position: absolute;
  left: 0;
  top: 53%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-size: cover;
}
.contacts-block__email:hover,
.contacts-block__skype:hover {
  color: #c14727;
}
.contacts-block__email::before {
  width: 19px;
  height: 14px;
  background-image: url(../img/header/email-icon.png);
}
.contacts-block__skype::before {
  width: 20px;
  height: 20px;
  background-image: url(../img/header/skype-icon.png);
}
.contacts-block__review {
  display: inline-block;
  color: #fff;
  position: relative;
  align-self: flex-start;
  font-size: 16.5px;
  line-height: 1.2;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  border-radius: 0;
  color: #e45556;
  background-color: transparent;
}
@media screen and (max-width: 600px) {
  .contacts-block__review {
    border-radius: 10px;
    color: #fff;
    line-height: 37px;
    background-color: #e45556;
    padding: 0 38px 0 53px;
    border: 1px solid #e45556;
  }
  .contacts-block__review::after {
    display: none;
  }
}
.contacts-block__review::before {
  left: 5px;
  right: 5px;
  bottom: 3px;
}
.contacts-block__btn {
  margin: 8px 0 15px;
}
.contacts-block .wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.accent {
  color: #c14624;
}
.header {
  position: relative;
}
.header.header-main-page .header-m-p {
  display: block;
}
.header.header-main-page .header-inner {
  display: none;
}
.header .header-m-p {
  display: none;
  padding-top: 35px;
}
.header .header-bg {
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 982px;
  height: 407px;
}
.header .container {
  position: relative;
}
.header .container::before {
  content: '';
  position: absolute;
  width: 824px;
  height: 42px;
  left: -120px;
  bottom: -42px;
  background-image: url(../img/header/header-shadow-left.png);
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 1430px) {
  .header .container::before {
    left: -25px;
    width: 734px;
  }
}
.header .container::after {
  content: '';
  position: absolute;
  width: 824px;
  height: 42px;
  right: -232px;
  bottom: -42px;
  background-image: url(../img/header/header-shadow-left.png);
  background-repeat: no-repeat;
  background-size: contain;
  transform: scaleX(-1);
}
@media screen and (max-width: 1650px) {
  .header .container::after {
    right: -120px;
  }
}
@media screen and (max-width: 1430px) {
  .header .container::after {
    right: 0;
    width: 734px;
  }
}
.header-m-p {
  background-color: #f0f0f0;
}
.header-m-p .container {
  height: 100%;
  min-height: 389px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header-m-p .header-block {
  display: flex;
  align-items: flex-start;
}
.header-m-p .header-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 28px;
}
.header-m-p .main-nav {
  display: flex;
  flex-direction: column;
}
.header-m-p .main-nav__item:not(:last-child) {
  margin-bottom: 3px;
}
.header-m-p .main-nav__link {
  display: inline-block;
  position: relative;
  line-height: 1;
  transition: all 0.3s linear;
}
.header-m-p .main-nav__link::after {
  background-color: #000;
}
.header-m-p .header-bottom {
  text-align: center;
  font-size: 30px;
  line-height: 1.4em;
  color: #2c2c2c;
  margin-bottom: 15px;
}
.header-m-p .header-bottom__bold {
  text-transform: uppercase;
  color: #c14624;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header-m-p .header-bottom {
    margin-top: 50px;
  }
}
.header-m-p .contacts-block {
  margin-left: auto;
}
.header-inner {
  background-color: #cd6b50;
  color: #fff;
}
.header-inner .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 10px;
}
.header-inner .header-logo__title {
  color: #fffbcc;
  font-size: 30px;
  letter-spacing: 1px;
}
.header-inner .header-logo__subtitle {
  color: rgba(255, 255, 255, 0.53);
  font-size: 15px;
  line-height: 19.5px;
}
.header-inner .main-nav {
  font-size: 18px;
  line-height: 1.3;
  margin-left: 48px;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.header-inner .main-nav__link:not(:last-child) {
  margin-right: 25px;
}
.header-inner .main-nav__link::after {
  background-color: #fff;
}
.header-inner .header-contacts {
  display: flex;
  align-items: center;
}
.header-inner .header-contacts__tel {
  color: #fffbcc;
  font-size: 26px;
  line-height: 40px;
  white-space: nowrap;
  margin-left: 10px;
  transition: all 0.3s linear;
}
.header-inner .header-contacts__tel:hover {
  color: #404040;
}
.header-inner .btn {
  background-color: #e35557;
  border: solid 1px #f08c8c;
  margin-left: 23px;
}
.header-inner .btn:hover {
  background-color: #fff;
}
@media screen and (max-width: 1200px) {
  .header-inner .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header-inner .main-nav {
    margin-left: 20px;
  }
}
@media screen and (max-width: 1180px) {
  .header .container {
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.26);
  }
  .header .container::before,
  .header .container::after {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .header .header-bg {
    background: none !important;
    display: none;
  }
  .header .container::before,
  .header .container::after {
    display: none;
  }
  .header-m-p .container {
    min-height: 315px;
  }
  .header-m-p .header-block {
    justify-content: center;
  }
  .header-m-p .contacts-block {
    margin-left: 50px;
  }
  .header-m-p .header-bottom__bold {
    display: none;
  }
  .header-inner .container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .header-inner .main-nav {
    order: 1;
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 10px;
    justify-content: center;
  }
  .header-inner .header-contacts {
    margin-left: 20px;
  }
}
@media screen and (max-width: 600px) {
  .header .header-m-p {
    padding-top: 0;
  }
  .header .header-m-p .container {
    padding: 0;
  }
  .header .header-m-p .header-block {
    flex-direction: column;
    align-items: center;
  }
  .header .header-m-p .header-logo {
    flex-direction: row;
    margin-right: 0;
    padding-top: 20px;
    padding-bottom: 10px;
    width: 100%;
    background-color: #e6e6e6;
  }
  .header .header-m-p .header-logo img {
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }
  .header .header-m-p .main-nav {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 5px;
    margin-bottom: 10px;
    width: 100%;
    background-color: #e6e6e6;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.26);
  }
  .header .header-m-p .main-nav__item:not(:last-child) {
    margin-right: 10px;
  }
  .header .header-m-p .contacts-block {
    margin-left: 0;
  }
  .header .header-m-p .header-bottom {
    margin-top: 10px;
    font-size: 20px;
  }
  .header .header-inner .container {
    flex-direction: column;
  }
  .header .header-inner .header-logo {
    text-align: center;
  }
  .header .header-inner .header-contacts {
    margin-left: 0;
  }
  .header .header-inner .header-contacts__tel {
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .header-inner .container {
    padding-bottom: 0;
  }
  .header-inner .main-nav__link {
    margin-bottom: 10px;
  }
  .header-inner .header-contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header-inner .header-contacts__tel {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .header-inner .btn {
    margin-left: 0;
  }
}
.footer {
  position: relative;
  background-color: #f0f0f0;
}
.footer::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 870px;
  background: transparent url(../img/footer/footer-bg.png) center / cover no-repeat;
  background-position-x: left;
}
.footer .container {
  position: relative;
  padding-top: 80px;
  padding-bottom: 20px;
}
.footer .container::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  background: transparent url(../img/footer/footer-shamp.png) center / cover no-repeat;
  width: 438px;
  height: 280px;
}
.footer .footer-logo-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer .logo-block {
  display: inline-block;
}
.footer .contacts-block {
  margin-left: 40px;
  margin-right: 45px;
  margin-top: 10px;
}
.footer .footer-shamp-descr {
  margin-top: 10px;
  font-size: 24px;
  line-height: 36px;
  color: #c24725;
  width: 345px;
}
.footer .footer-shamp-descr__text:nth-child(2) {
  text-align: right;
}
.footer .footer-shamp-descr__text:nth-child(3) {
  text-align: right;
  padding-right: 70px;
}
.footer__dev {
  margin-top: 70px;
  font-size: 14px;
}
.footer__dev a {
  color: #c24927;
}
.footer-block {
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.footer .ya-share2__list {
  display: flex;
  margin-bottom: 2px;
}
.footer .ya-share2__container_size_m li.ya-share2__item {
  margin: 0 4px;
}
.footer .ya-share2__container_size_m li.ya-share2__item:nth-of-type(1) {
  margin-left: 0;
}
@media screen and (max-width: 1700px) {
  .footer::before {
    width: 725px;
  }
}
@media screen and (max-width: 1500px) {
  .footer::before {
    width: 650px;
  }
}
@media screen and (max-width: 1370px) {
  .footer::before {
    width: 590px;
  }
}
@media screen and (max-width: 1200px) {
  .footer::before {
    width: 500px;
  }
  .footer .container {
    padding-top: 40px;
  }
  .footer .container::before {
    width: 306px;
    height: 196px;
  }
}
@media screen and (max-width: 992px) {
  .footer .footer-shamp-descr {
    width: auto;
    line-height: 1.2;
    font-size: 20px;
    text-align: right;
  }
  .footer .footer-shamp-descr__text {
    display: inline;
  }
  .footer .footer-shamp-descr__text:nth-child(3) {
    padding-right: 0;
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .footer::before {
    width: 100%;
  }
  .footer .container::before {
    display: none;
  }
  .footer .footer-shamp-descr {
    display: none;
  }
  .footer .footer-block {
    justify-content: space-around;
  }
  .footer .contacts-block {
    margin-right: 0;
  }
  .footer__dev {
    text-align: center;
  }
}
@media screen and (max-width: 480px) {
  .footer .footer-block {
    flex-direction: column;
    align-items: center;
  }
  .footer .logo-block {
    margin-bottom: 20px;
  }
  .footer .share-soc {
    justify-content: center;
  }
  .footer .contacts-block {
    margin-left: 0;
    margin-top: 20px;
  }
}
.extra-header {
  box-shadow: 2.5px 4.3px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}
.extra-header .container::after,
.extra-header .container::before {
  display: none;
}
.extra-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.extra-nav__item {
  position: relative;
  padding: 18px 0 18px 35px;
}
.extra-nav__item:not(:last-child) {
  margin-right: 10px;
}
.extra-nav__item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-size: cover;
}
.extra-nav__item:nth-child(1)::before {
  width: 24px;
  height: 24px;
  background-image: url(../img/header/strategy-icon.png);
}
.extra-nav__item:nth-child(2)::before {
  width: 30px;
  height: 26px;
  background-image: url(../img/header/sites-icon.png);
}
.extra-nav__item:nth-child(3)::before {
  width: 28px;
  height: 27px;
  background-image: url(../img/header/selling-icon.png);
}
.extra-nav__item:nth-child(4)::before {
  width: 29px;
  height: 24px;
  background-image: url(../img/header/ad-companies-icon.png);
}
.extra-nav__item:nth-child(5)::before {
  width: 28px;
  height: 30px;
  background-image: url(../img/header/corp-culture-icon.png);
}
.extra-nav__link {
  font-size: 20px;
  line-height: 1;
  color: #c24725;
  display: inline-block;
}
@media screen and (max-width: 992px) {
  .extra-nav {
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 64px;
    padding: 10px 0;
  }
  .extra-nav__item {
    padding-top: 0;
    padding-bottom: 0;
    margin: 5px 0;
  }
  .extra-nav__link {
    font-size: 18px;
  }
}
.test {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 35px 0 19px;
}
.test__title {
  text-align: center;
  font-size: 31px;
  margin-bottom: 5px;
}
.test__subtitle {
  font-size: 24px;
  color: #3c3c3c;
  text-align: center;
}
.test__content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.test__what {
  width: 315px;
  height: 315px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 2.5px 4.3px 5px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 35px;
  margin-top: 15px;
}
.test__what-title {
  font-size: 31px;
  line-height: 36px;
  color: #3c3c3c;
  margin-bottom: 30px;
}
.test__what-text {
  line-height: 1;
  color: #525252;
}
.test__what-text .bottom {
  display: none;
}
.test__list {
  color: #434343;
  display: flex;
  flex-direction: column;
  position: relative;
}
.test__list::before,
.test__list::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-size: cover;
  width: 43px;
  height: 88px;
  background-image: url(../img/test/test-arrow.png);
}
.test__list::before {
  left: -70px;
}
.test__list::after {
  right: -70px;
}
.test__item {
  font-size: 20px;
  line-height: 2;
  position: relative;
  cursor: pointer;
  padding-left: 33px;
  padding-right: 10px;
}
.test__item::before {
  content: '';
  position: absolute;
  left: 10px;
  top: 17px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #6a6a6a;
  transition: all 0.3s linear;
}
.test__item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #c14624;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.test__item.active {
  list-style-type: none;
  color: #fff;
}
.test__item.active::before {
  background-color: rgba(255, 255, 255, 0);
}
.test__item.active::after {
  height: 100%;
}
.test__item.active .test__item-text::after {
  display: none;
}
.test__item-text {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.test__item-text::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  height: 1px;
  background-color: #434343;
  transition: all 0.3s linear;
}
.test__item-text:hover::after {
  background-color: rgba(255, 255, 255, 0);
}
.test__answer {
  height: 315px;
  margin-top: 15px;
}
.test__answer-main {
  box-shadow: 2.5px 4.3px 18px 0 rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  overflow: hidden;
  width: 315px;
  height: 315px;
  position: relative;
  border: 3px solid #ffffff;
}
.test__answer-main:not(:first-child) {
  display: none;
}
.test__answer-inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.62);
}
.test__answer-inner img {
  width: auto;
  height: auto;
}
.test__answer-icon {
  display: inline-block;
  vertical-align: middle;
}
.test__answer-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 52px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .test__answer-text {
    width: auto;
  }
}
.test__share {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  flex-wrap: wrap;
}
.test__share-text {
  font-size: 18px;
  line-height: 1;
  color: #3d4b5b;
  margin-right: 16px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .test__share-text {
    width: auto;
  }
}
@media screen and (max-width: 1200px) {
  .test__content {
    align-items: center;
  }
  .test__list::before,
  .test__list::after {
    width: 25px;
    height: 53px;
  }
  .test__list::before {
    left: -45px;
  }
  .test__list::after {
    right: -45px;
  }
  .test__what,
  .test__answer {
    width: 250px;
    height: 250px;
    margin-top: 0;
  }
  .test__what-title {
    margin-bottom: 15px;
    font-size: 26px;
    line-height: 1.2;
  }
  .test__answer-main {
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 992px) {
  .test__content {
    flex-wrap: wrap;
    justify-content: center;
  }
  .test__what {
    width: 100%;
    height: auto;
    background: none;
    box-shadow: none;
    margin-bottom: 20px;
  }
  .test__what-text br {
    display: none;
  }
  .test__list {
    margin-right: 15px;
  }
  .test__list::before,
  .test__list::after {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .test__title span {
    display: none;
  }
  .test__share-text {
    font-size: 16px;
    margin-right: 5px;
  }
  .test__content {
    flex-direction: column;
  }
  .test__what-text .right {
    display: none;
  }
  .test__what-text .bottom {
    display: inline;
  }
  .test__list {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .test__item {
    font-size: 18px;
  }
  .test__share {
    margin-top: 25px;
  }
}
@media screen and (max-width: 400px) {
  .test__share {
    flex-direction: column;
  }
  .test__share-text {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .ya-share2__container_size_m li.ya-share2__item {
    margin-left: 15px;
  }
}
.why {
  padding: 65px 0 57px;
  text-align: center;
  background-color: #f0f0f0;
}
.why__title {
  font-size: 31px;
  line-height: 1;
  color: #bf4625;
  margin-bottom: 5px;
}
.why__subtitle {
  font-size: 24px;
  line-height: 31px;
  color: #000;
  text-transform: none;
}
.why__interest {
  padding: 0 26px 0 33px;
  line-height: 48px;
}
.why-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 60px;
}
.why-noactual {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.why-noactual__title {
  font-size: 20px;
  line-height: 31px;
  color: #df0024;
  display: inline-block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 25px;
}
.why-noactual__title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 34px;
  height: 55px;
  background: transparent url(../img/why/why-noactual-icon.png) center / cover no-repeat;
}
.why-actual__title {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 25px;
}
.why-actual__title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 46px;
  height: 57px;
  background: transparent url(../img/why/why-actual-icon.png) center / cover no-repeat;
}
.why-actual__list {
  text-align: left;
  padding: 0 35px 15px 35px;
  position: relative;
  border-bottom: 1px solid #dddddd;
}
.why-actual__item {
  font-size: 20px;
  line-height: 50px;
  position: relative;
  padding-left: 28px;
}
.why-actual__item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 16px;
  width: 18px;
  height: 17px;
  background: transparent url(../img/why/star-icon.png) center / cover no-repeat;
}
.why-links {
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
  margin: 35px 0 25px;
}
.why-links a {
  color: #c24927;
  line-height: 1;
  position: relative;
}
.why-links a::before {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}
.why-links__our::before {
  width: 35px;
  height: 35px;
  background-image: url(../img/why/why-case-icon.png);
  left: -48px;
  bottom: -5px;
}
.why-links__strategy::before {
  width: 51px;
  height: 49px;
  background-image: url(../img/why/monitor-icon.png);
  left: -54px;
  bottom: -10px;
}
@media screen and (max-width: 1200px) {
  .why-noactual img {
    max-width: 420px;
    max-height: 230px;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 992px) {
  .why-block {
    flex-direction: column;
    align-items: center;
  }
  .why-noactual {
    margin-bottom: 60px;
  }
  .why-actual__list {
    padding: 0;
  }
  .why-actual__item {
    line-height: 1.5;
    padding: 10px 0 10px 28px;
  }
  .why-links {
    padding-right: 0;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .why-noactual {
    display: none;
  }
  .why__title span {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .why {
    padding: 40px 0;
  }
}
@media screen and (max-width: 330px) {
  .why-links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .why-links__our {
    margin-bottom: 30px;
  }
}
.partners .container {
  position: relative;
  padding: 100px 0 80px;
}
.partners .container::before,
.partners .container::after {
  content: '';
  position: absolute;
  bottom: -57px;
  height: 57px;
  background-repeat: no-repeat;
  background-size: cover;
}
.partners .container::before {
  left: 0;
  width: 630px;
  background-image: url(../img/partners/partners-shadow-left.png);
}
.partners .container::after {
  right: 0;
  width: 657px;
  background-image: url(../img/partners/partners-shadow-right.png);
}
.partners__title {
  text-align: center;
  color: #fff;
  background-color: #a5b0ba;
  line-height: 80px;
}
.partners__carousel {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  position: relative;
}
.partners__carousel .owl-stage {
  display: flex;
  align-items: center;
}
.partners__carousel .owl-item {
  text-align: center;
}
.partners__carousel .owl-item .img-wrapper {
  max-width: 120px;
  max-height: 120px;
}
.partners .owl-carousel.owl-loaded {
  display: flex;
}
@media screen and (max-width: 1200px) {
  .partners .container {
    box-shadow: 2.5px 4.3px 5px 0 rgba(0, 0, 0, 0.1);
  }
  .partners .container::before,
  .partners .container::after {
    display: none;
  }
  .partners__carousel {
    padding: 0 40px;
  }
}
@media screen and (max-width: 992px) {
  .partners .container {
    padding: 40px 0;
  }
}
.request {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 25px;
}
.request__img-wrapper {
  align-self: flex-end;
  height: 473px;
  min-width: 311px;
}
.request__inner-text {
  margin-top: 25px;
  font-size: 24px;
  color: #393939;
  line-height: 1.7;
  margin-left: -44px;
}
.request__big-text {
  font-size: 32.5px;
  text-transform: uppercase;
  color: #363636;
}
.request__text:last-child {
  color: #3d3d3d;
}
.request-block {
  display: flex;
  justify-content: space-between;
}
.form-request {
  position: relative;
  z-index: 1;
  width: 342px;
  margin-top: 25px;
}
.form-request .btn {
  margin-bottom: 20px;
}
.form-request textarea {
  height: 168px;
}
.form-request button {
  font-size: 18px;
  line-height: 48px;
  padding: 0 64px 0 71px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.form-request button:hover {
  color: #fff;
}
@media screen and (max-width: 1200px) {
  .request__inner-text {
    line-height: 1.5;
    font-size: 20px;
  }
  .request__big-text {
    font-size: 22px;
  }
}
@media screen and (max-width: 992px) {
  .request-block {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .request__inner-text {
    order: -1;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
}
@media screen and (max-width: 700px) {
  .request {
    padding-bottom: 30px;
  }
  .request .request-block {
    flex-direction: column;
    align-items: center;
  }
  .request__inner-text {
    margin-top: 0;
  }
  .request__img-wrapper {
    align-self: auto;
    width: 250px;
    height: 380px;
    min-width: 250px;
  }
  .request .form-request {
    margin-top: 0;
  }
}
@media screen and (max-width: 480px) {
  .request .form-request {
    width: 100%;
  }
}
.hero {
  background-color: #f0f0f0;
}
.hero .container {
  position: relative;
  padding-top: 45px;
  padding-bottom: 95px;
}
.hero .container::before,
.hero .container::after {
  content: "";
  position: absolute;
  width: 824px;
  height: 42px;
  bottom: -42px;
  background-image: url(../img/header/header-shadow-left.png);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
.hero .container::before {
  left: -120px;
}
@media screen and (max-width: 1430px) {
  .hero .container::before {
    left: -25px;
    width: 734px;
  }
}
.hero .container::after {
  right: -232px;
  transform: scaleX(-1);
}
@media screen and (max-width: 1650px) {
  .hero .container::after {
    right: -120px;
  }
}
@media screen and (max-width: 1430px) {
  .hero .container::after {
    right: 0;
    width: 734px;
  }
}
.hero__main-title {
  color: #e35557;
  line-height: 1;
  display: inline-block;
  margin-right: 23px;
  font-weight: normal;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hero__main-title {
    width: auto;
  }
}
.hero__title {
  color: #434343;
  text-transform: none;
  font-weight: normal;
}
.hero__text {
  line-height: 28px;
  text-align: justify;
}
.hero__text span {
  color: #c04421;
}
.hero .container p.hero__text-nm {
  margin: 0;
}
.about-hero-top {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
}
.about-hero__title {
  display: inline-block;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .about-hero__title {
    width: auto;
  }
}
.about-hero__woman {
  margin-top: 32px;
}
.about-hero__subtext {
  line-height: 24px;
  color: #363636;
  text-decoration: underline;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.about-hero__text span {
  font-size: 24px;
}
.about-hero .about-hero-quote {
  position: relative;
  margin-left: -100px;
}
.about-hero .about-hero-quote__text {
  position: absolute;
  left: 125px;
  top: 50px;
  text-align: center;
  width: 325px;
  color: #c14724;
  font-size: 18px;
  line-height: 24px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .about-hero .about-hero-quote__text {
    width: 350px;
  }
}
@media screen and (max-width: 1180px) {
  .hero .container {
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.26);
  }
  .hero .container::before,
  .hero .container::after {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .about-hero__woman {
    width: 336px;
    height: 232px;
    margin-top: 90px;
  }
}
@media screen and (max-width: 768px) {
  .hero .container {
    padding-bottom: 40px;
  }
  .about-hero__woman {
    margin-top: 0;
    width: 268px;
    height: 185px;
  }
  .about-hero .about-hero-quote {
    margin-left: 0;
  }
  .about-hero .about-hero-quote img {
    display: none;
  }
  .about-hero .about-hero-quote__text {
    position: relative;
    left: auto;
    top: auto;
    width: auto;
  }
}
@media screen and (max-width: 600px) {
  .hero__title {
    text-align: center;
  }
  .about-hero {
    text-align: center;
  }
  .about-hero-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 480px) {
  .hero__text {
    line-height: 1.4;
  }
}
.diplomas {
  padding: 45px 0 100px;
}
.diplomas__title {
  text-transform: none;
  font-weight: normal;
  text-align: center;
  margin-bottom: 40px;
}
.diplomas-carousel .owl-stage {
  display: flex;
  align-items: center;
}
.diplomas-carousel .owl-item {
  text-align: center;
}
.diplomas-carousel .owl-item img {
  box-shadow: -1px 3.9px 5px 0 rgba(0, 0, 0, 0.39);
}
.diplomas-carousel .img-wrapper {
  max-width: 335px;
  max-height: 337px;
}
@media screen and (max-width: 768px) {
  .diplomas {
    padding: 40px 0;
  }
  .diplomas .img-wrapper {
    max-width: 170px;
    max-height: 237px;
  }
}
.a-links-s {
  background-color: #e0e0e0;
  position: relative;
}
.a-links-s .container {
  padding: 55px 0 52px;
}
.a-links-s .container {
  position: relative;
}
.a-links-s .container::before,
.a-links-s .container::after {
  content: "";
  position: absolute;
  width: 824px;
  height: 42px;
  bottom: -42px;
  background-image: url(../img/header/header-shadow-left.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.a-links-s .container::before {
  left: -120px;
}
@media screen and (max-width: 1430px) {
  .a-links-s .container::before {
    left: -25px;
    width: 734px;
  }
}
.a-links-s .container::after {
  right: -120px;
  transform: scaleX(-1);
}
@media screen and (max-width: 1650px) {
  .a-links-s .container::after {
    right: -120px;
  }
}
@media screen and (max-width: 1430px) {
  .a-links-s .container::after {
    right: 0;
    width: 734px;
  }
}
.a-links-s__btn {
  margin-right: 130px;
  font-size: 20px;
}
.a-links-s__link {
  position: relative;
  color: #bf4624;
  font-size: 20px;
  line-height: 1;
}
.a-links-s__link::before,
.a-links-s__link::after {
  content: '';
  position: absolute;
  transition: all 0.3s linear;
}
.a-links-s__link::before {
  width: 21px;
  height: 21px;
  background: transparent url(../img/icons/wheel-icon--red.png) center / cover no-repeat;
  top: 50%;
  transform: translateY(-50%);
  left: -28px;
}
.a-links-s__link::after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #bf4624;
}
.a-links-s .a-links-s-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1180px) {
  .a-links-s .container {
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.26);
  }
  .a-links-s .container::before,
  .a-links-s .container::after {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .a-links-s .container {
    padding: 30px;
  }
  .a-links-s-block {
    flex-direction: column;
  }
  .a-links-s__btn {
    margin-right: 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .a-links-s__btn {
    margin-right: 0;
    padding: 0 30px;
  }
}
@media screen and (max-width: 480px) {
  .a-links-s__link {
    font-size: 16px;
  }
}
.a-partners {
  background-color: #f0f0f0;
  padding: 50px 0 42px;
}
.a-partners__title {
  text-align: center;
  text-transform: none;
  margin-bottom: 45px;
}
.a-partners__item {
  position: relative;
  width: 344px;
  min-height: 589px;
  background-color: #fff;
  border: solid 1px #c5573c;
  margin: auto;
  margin-bottom: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.a-partners__item::after {
  content: '';
  position: absolute;
  left: -30px;
  right: 0;
  bottom: -42px;
  width: 387px;
  height: 42px;
  background: transparent url(../img/a-partners/a-partners-shadow.png) center / cover no-repeat;
}
.a-partners__top-text {
  color: #c04421;
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
}
.a-partners__subtext {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  text-transform: uppercase;
  position: relative;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .a-partners__subtext {
    width: auto;
  }
}
.a-partners__subtext::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  height: 1px;
  background-color: #000;
}
.a-partners .owl-carousel .owl-item img {
  width: auto;
}
.a-p-dizarti {
  padding-left: 25px;
  padding-right: 27px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .a-p-dizarti {
    padding-top: 20px;
  }
}
.a-p-dizarti__subtitle {
  color: #898989;
  line-height: 24px;
  margin-top: 5px;
}
.a-p-dizarti__main-text {
  font-size: 15px;
  line-height: 22px;
  margin-top: 25px;
}
.a-p-dizarti__main-text .accent {
  text-transform: uppercase;
}
.a-p-dizarti .a-partners__top-text {
  margin-top: 25px;
  margin-bottom: 30px;
}
.a-p-dizarti__b-t {
  font-weight: bold;
}
.a-p-chantecler .img-wrapper {
  width: 342px;
  overflow: hidden;
  margin-bottom: auto;
  position: relative;
}
.a-p-chantecler .img-wrapper::after {
  content: '';
  position: absolute;
}
.a-p-chantecler .a-partners__subtext {
  margin-bottom: auto;
}
.a-p-itspb__top {
  margin-top: 20px;
}
.a-p-itspb__top img.a-p-itspb__logo-img {
  display: inline-block;
  vertical-align: middle;
}
.a-p-itspb__title {
  display: inline-block;
  vertical-align: middle;
  color: #35aee5;
  text-shadow: -0.5px 1.9px 2px rgba(0, 0, 0, 0.32);
  font-size: 50px;
  line-height: 60px;
  font-family: "HeliosCondLightNormal", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.a-p-itspb__subtitle {
  color: #34ade5;
  font-family: "HeliosCondLightNormal", sans-serif;
  font-size: 15.5px;
  line-height: 1;
  margin-bottom: 30px;
}
.a-p-itspb .a-partners__subtext {
  margin-bottom: 5px;
}
.a-p-itspb .a-partners__top-text {
  padding: 0 18px 0 30px;
  margin-bottom: 22px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .a-p-itspb .a-partners__top-text {
    padding: 0 18px 0 10px;
  }
}
@media screen and (max-width: 768px) {
  .a-partners {
    padding-top: 35px;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 480px) {
  .a-partners__item {
    width: 290px;
  }
  .a-partners__item::after {
    width: 309px;
    height: 33px;
    bottom: -33px;
  }
  .a-p-chantecler .img-wrapper {
    width: 288px;
  }
  .a-partners .owl-carousel .owl-item .a-p-dizarti img {
    width: auto;
  }
  .a-partners .owl-carousel .owl-item img {
    width: 100%;
  }
  .a-partners .owl-carousel .owl-item .a-p-itspb img {
    width: auto;
  }
  .a-partners .owl-carousel .owl-item .a-p-itspb .img-wrapper img {
    width: 100%;
  }
}
.s-hero__title {
  margin-bottom: 15px;
}
.s-hero p {
  margin-bottom: 5px;
}
.s-hero p:last-child {
  margin-top: 20px;
}
.s-case {
  padding: 35px 0 0 0;
}
.s-case.s-selling,
.s-case.s-cult {
  background-color: #f0f0f0;
}
.s-case .custom-arrow {
  top: 40%;
}
.s-case__title {
  text-align: center;
  margin-bottom: 25px;
}
.s-case__subtitle {
  text-align: center;
  margin-bottom: 30px;
}
.s-case__text {
  font-size: 16px;
  line-height: 18px;
  color: #c14624;
  padding: 0 33px;
  margin-top: 5px;
}
.s-case__item {
  padding-bottom: 64px;
  text-align: center;
  position: relative;
}
.s-case .img-wrapper {
  width: 321px;
  height: 241px;
  border: solid 1px #aeaeae;
  position: relative;
}
.s-case .img-wrapper::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -58px;
  width: 299px;
  height: 64px;
  background: transparent url(../img/s-case/s-case-shadow.png) center / cover no-repeat;
  z-index: -1;
}
.s-case .img-wrapper img {
  height: 100%;
}
.s-case__inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(71, 91, 130, 0.8);
  color: #fff;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s linear;
}
.s-case__inner img.s-case__inner-img {
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.s-case__inner p {
  font-size: 14px;
  line-height: 28px;
}
.s-case .img-wrapper:hover .s-case__inner {
  opacity: 1;
  z-index: 1;
}
@media screen and (max-width: 480px) {
  .s-case .img-wrapper {
    width: 256px;
    height: 192px;
  }
}
.articles .container {
  padding-bottom: 55px;
}
.articles__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}
.articles__item {
  width: 344px;
  height: 589px;
  border: solid 1px #c5573c;
  position: relative;
  background-color: #fff;
  margin-bottom: 32px;
}
.articles__item::after {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  bottom: -42px;
  width: 387px;
  height: 42px;
  background: transparent url(../img/a-partners/a-partners-shadow.png) 50% / cover no-repeat;
}
.articles__item:nth-child(3n+2) {
  margin-left: 43px;
  margin-right: 43px;
}
.articles__item .img-wrapper {
  width: 100%;
  height: 335px;
}
.articles__content {
  overflow: hidden;
  height: 212px;
  padding: 5px 15px 20px 19px;
  text-align: right;
}
.articles__title {
  font-size: 20px;
  line-height: 1.2;
  color: #c14624;
  text-decoration: underline;
  transition: all 0.3s linear;
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
  overflow: hidden;
  max-height: 77px;
}
.articles__title:hover {
  text-decoration: none;
}
.articles__text {
  font-size: 16px;
  line-height: 18px;
  color: #404040;
  text-align: justify;
  height: 145px;
  overflow: hidden;
}
.articles__more {
  color: #c14624;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  font-style: italic;
  float: right;
  margin-right: 15px;
}
.articles__more::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #c14624;
}
.articles__more:hover::after {
  background-color: rgba(255, 255, 255, 0);
}
.articles__all {
  position: relative;
  color: #c14624;
  font-style: italic;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 18px;
  line-height: 1;
  display: inline-block;
}
.articles__all::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #c14624;
  transition: all 0.3s linear;
}
.articles__all:hover::after {
  background-color: rgba(255, 255, 255, 0);
}
@media screen and (max-width: 1200px) {
  .articles__item:nth-child(3n+2) {
    margin-left: 0;
    margin-right: 0;
  }
  .articles__list .articles__item {
    margin: 22px 10px 0 10px;
  }
}
@media screen and (max-width: 768px) {
  .articles__list {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  .articles__list .articles__item {
    margin-right: 0;
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .articles__list .articles__item {
    width: 290px;
  }
  .articles__list .articles__item::after {
    width: 309px;
    height: 33px;
    bottom: -33px;
  }
}
.article {
  background-color: #f0f0f0;
}
.article .container {
  padding-top: 25px;
  padding-bottom: 150px;
}
.article .article-top {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
}
.article .img-wrapper {
  max-width: 373px;
  max-height: 254px;
}
.article__title {
  margin-left: 33px;
  max-width: 438px;
  color: #363636;
  font-size: 36px;
  line-height: 48px;
  text-transform: none;
}
.article__text {
  text-align: justify;
}
.article__text:nth-child(even) {
  margin-bottom: 30px;
}
.article .article-pag {
  display: flex;
  justify-content: center;
  color: #c14624;
  font-size: 18px;
  line-height: 1;
  font-style: italic;
}
.article .article-pag__prev {
  margin-right: 79px;
}
@media screen and (max-width: 768px) {
  .article .container {
    padding-bottom: 40px;
  }
  .article .article-top {
    flex-direction: column;
    align-items: center;
  }
  .article__title {
    max-width: none;
    margin-top: 10px;
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .article__title {
    text-align: center;
    line-height: 1.2;
  }
  .article__text {
    line-height: 1.2;
  }
  .article .article-pag {
    flex-direction: column;
    align-items: center;
  }
  .article .article-pag__prev {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.reviews {
  background-color: #f0f0f0;
}
.reviews__title {
  color: #363636;
  text-transform: none;
  padding: 30px 0;
}
.reviews__name {
  position: relative;
  background-color: #f0f0f0;
  display: block;
  padding-right: 9px;
  font-size: 22px;
  line-height: 1;
  font-style: normal;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews__name {
    margin-right: auto;
  }
}
.reviews__time {
  position: relative;
  background-color: #f0f0f0;
  padding-left: 40px;
  font-size: 16px;
  line-height: 22px;
}
.reviews__time::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 0;
  background: transparent url(../img/icons/note-icon.png);
  width: 30px;
  height: 28px;
}
.reviews__rate {
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  padding-left: 33px;
  position: relative;
  margin-top: 5px;
}
.reviews__rate::before {
  content: '';
  position: absolute;
  left: 0;
  top: 40%;
  transform: translateY(-50%);
  width: 28px;
  height: 26px;
  background: transparent url(../img/icons/star-icon.png) center / cover no-repeat;
}
.reviews__rate-num {
  color: #c14624;
  margin-left: 5px;
}
.reviews__text-block {
  margin: 15px 0 10px;
  padding-left: 43px;
  padding-right: 40px;
  text-align: justify;
  line-height: 1.22;
}
.reviews__text-block.oh {
  overflow: hidden;
  height: 45px;
}
.reviews__read-more {
  color: #c14624;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
}
.reviews .reviews-block {
  padding: 20px 0 20px 0;
}
.reviews .reviews-block:nth-child(odd) {
  background-color: #fff;
}
.reviews .reviews-block:nth-child(odd) .reviews__name,
.reviews .reviews-block:nth-child(odd) .reviews__time {
  background-color: #fff;
}
.reviews .reviews-header {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  color: #c14624;
  padding: 10px 0;
}
.reviews .reviews-header::before {
  content: '';
  position: absolute;
  left: 0;
  right: -100vw;
  top: 28px;
  height: 1px;
  background-color: #c14624;
}
@media screen and (max-width: 768px) {
  .reviews__title {
    text-align: center;
  }
  .reviews .reviews-header {
    flex-direction: column;
    align-items: center;
  }
  .reviews .reviews-header::before {
    display: none;
  }
  .reviews__text-block {
    padding: 0;
  }
  .reviews__name {
    padding-right: 0;
    margin-bottom: 15px;
  }
  .reviews__rate {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
.feedback {
  background-color: #f0f0f0;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 27px;
}
.feedback .feedback-block {
  display: flex;
  justify-content: space-between;
}
.feedback .form-feedback {
  flex-grow: 1;
  margin-left: 55px;
  margin-top: 30px;
  position: relative;
}
.feedback .form-feedback::before {
  content: '';
  position: absolute;
  left: -100px;
  top: 75px;
  background: transparent url(../img/icons/note-icon--white.png) center / cover no-repeat;
  width: 82px;
  height: 75px;
}
.feedback .form-feedback input,
.feedback .form-feedback textarea {
  background-color: rgba(255, 255, 255, 0.8);
}
.feedback .form-feedback input {
  height: 50px;
}
.feedback .form-feedback textarea {
  height: 176px;
  margin-bottom: 10px;
}
.feedback__title {
  text-transform: none;
  font-size: 32.5px;
  line-height: 65px;
  margin-bottom: 10px;
}
.feedback__rate-text {
  color: rgba(47, 47, 47, 0.73);
  display: flex;
  align-items: flex-start;
  padding-left: 12px;
}
.feedback__rate {
  margin-left: 5px;
  margin-top: -2px;
}
.feedback__btn {
  display: block;
  margin-left: auto;
  font-size: 18px;
  line-height: 48px;
  padding: 0 62px 0 73px;
  margin-bottom: 20px;
}
.feedback .img-wrapper {
  width: 311px;
  height: 473px;
  align-self: flex-end;
  flex-shrink: 0;
}
.feedback .wrap {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 992px) {
  .feedback .form-feedback {
    margin-bottom: 15px;
  }
  .feedback__btn {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1030px) {
  .feedback .wrap {
    flex-direction: column;
  }
  .feedback__btn {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .feedback .img-wrapper {
    display: none;
  }
  .feedback .form-feedback {
    margin-left: 0;
    margin-top: 0;
  }
  .feedback .form-feedback::before {
    display: none;
  }
  .feedback__btn {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 480px) {
  .feedback__title {
    line-height: 1.3;
  }
  .feedback__rate-text {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }
  .feedback__rate {
    margin-left: 0;
    margin-top: 10px;
  }
}
.contacts {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding: 35px 0 25px;
}
.contacts .contacts-wrap {
  display: flex;
  justify-content: space-between;
  padding-right: 23px;
}
.contacts__top-text,
.contacts__top-subtext {
  font-size: 24px;
}
.contacts__top-subtext {
  color: #363636;
}
.contacts hr {
  margin: 25px 0 35px;
  max-width: 514px;
  width: 100%;
}
.contacts__item {
  font-weight: normal;
  display: block;
  font-size: 24px;
  line-height: 65px;
}
.contacts__item-text {
  color: #363636;
  margin-left: 5px;
}
.contacts .contacts-form {
  max-width: 342px;
  width: 100%;
  text-align: center;
}
.contacts .contacts-form__title {
  margin-bottom: 15px;
}
.contacts .contacts-form textarea {
  margin-bottom: 10px;
}
.contacts .contacts-form__btn {
  line-height: 48px;
  padding: 0 65px 0 70px;
}
.contacts .c-where {
  margin-top: 20px;
  max-width: 700px;
}
.contacts .c-where__title {
  margin-bottom: 15px;
}
.contacts .c-where__text {
  margin-bottom: 10px;
}
@media screen and (max-width: 992px) {
  .contacts .contacts-wrap {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-right: 0;
  }
  .contacts hr {
    margin: 10px 0;
  }
  .contacts__item {
    line-height: 1.6;
  }
  .contacts .contacts-form {
    margin-top: 25px;
  }
}
@media screen and (max-width: 600px) {
  .contacts__item-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  .contacts__top-subtext {
    font-size: 18px;
  }
  .contacts__item {
    font-size: 17px;
  }
}
.strategy-hero .container {
  padding-bottom: 75px;
}
.strategy-hero.hero .hero__qoute {
  margin: 0;
  margin-top: 25px;
  font-style: italic;
}
.strategy-hero.hero .hero__text {
  margin-top: 25px;
}
.strategy-hero.hero .hero__text-nm {
  margin-top: 0;
}
.strategy-cases__title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 65px;
}
.strategy-cases .s-case__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0;
}
.strategy-cases .s-case__item .wrap {
  width: 30%;
  padding-bottom: 64px;
}
.strategy-cases.s-case .custom-arrow {
  top: 43%;
}
.strategy-cases .s-strategy-carousel-m {
  display: none;
}
@media screen and (max-width: 1200px) {
  .strategy-cases .s-strategy-carousel {
    display: none;
  }
  .strategy-cases .s-strategy-carousel-m {
    display: block;
  }
  .strategy-cases .s-case__item {
    justify-content: center;
  }
  .strategy-cases .s-case__item .wrap {
    width: auto;
  }
}
@media screen and (max-width: 768px) {
  .strategy-cases__title {
    margin-bottom: 20px;
  }
  .strategy-cases .s-case__item .wrap {
    padding-bottom: 48px;
  }
  .s-case .img-wrapper {
    width: 256px;
    height: 192px;
  }
}
.sites-hero .container {
  padding-bottom: 65px;
}
.sites-hero__wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.sites-hero__text {
  width: 46% !important;
  font-size: 18px;
  line-height: 22px;
}
@media screen and (max-width: 768px) {
  .sites-hero .container {
    padding-bottom: 40px;
  }
  .sites-hero__wrap {
    display: block;
  }
  .sites-hero__text {
    width: auto !important;
  }
  .sites-hero__text:not(:last-child) {
    margin-bottom: 10px;
  }
}
.sites-cases {
  padding-top: 30px;
}
.sites-cases .sites-cases-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sites-cases__title {
  text-align: center;
  text-transform: none;
  margin-bottom: 25px;
}
.sites-cases__item {
  width: 45%;
  text-align: center;
  margin-bottom: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.sites-cases__item .img-wrapper {
  border-radius: 5px;
  width: 498px;
  height: 352px;
}
.sites-cases__name {
  color: #c14624;
  border-bottom: 1px solid #c14624;
  line-height: 1;
  display: inline-block;
  position: relative;
  margin-top: 10px;
  transition: all 0.3s linear;
}
.sites-cases__name:hover {
  border-color: rgba(255, 255, 255, 0);
  color: #434343;
}
.sites-cases__text {
  color: #494949;
  line-height: 1;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .sites-cases__item .img-wrapper {
    width: 400px;
    height: 248px;
  }
}
@media screen and (max-width: 992px) {
  .sites-cases__item .img-wrapper {
    width: 300px;
    height: 189px;
  }
}
@media screen and (max-width: 768px) {
  .sites-cases .sites-cases-block {
    flex-direction: column;
    align-items: center;
  }
  .sites-cases__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
  }
  .sites-cases__item .img-wrapper {
    margin-bottom: 5px;
  }
  .sites-cases__name {
    transform: none;
  }
  .sites-cases__text {
    transform: none;
  }
}
@media screen and (max-width: 768px) {
  .sites-cases__name,
  .sites-cases__text {
    font-size: 16px;
  }
}
.selling-hero .container {
  padding-bottom: 60px;
}
.selling-hero.hero .hero__text {
  margin-top: 24px;
}
.s-selling-s {
  background-color: #fff;
}
.s-selling-pr {
  background-color: #f0f0f0;
}
.a-company-hero .hero__text {
  margin-top: 16px;
  line-height: 22px;
}
.a-company-hero .hero__qoute {
  font-style: italic;
  line-height: 22px;
  margin-top: 20px;
}
.a-company-hero ul {
  padding-left: 58px;
}
.a-company-hero ul li {
  position: relative;
  line-height: 22px;
  max-width: 958px;
  text-align: justify;
}
.a-company-hero ul li:first-child {
  margin-top: 10px;
}
.a-company-hero ul li:not(:last-child) {
  margin-bottom: 20px;
}
.a-company-hero ul li::before {
  content: '';
  position: absolute;
  left: -17px;
  top: 13px;
  width: 4px;
  height: 4px;
  background-color: #c14624;
  border-radius: 50%;
}
@media screen and (max-width: 480px) {
  .a-company-hero ul {
    padding-left: 20px;
  }
}
.a-comp-cases {
  padding-top: 30px;
}
.a-comp-cases .s-case__title span {
  text-transform: none;
}
.a-comp-cases .s-case__item {
  padding-bottom: 0;
}
.a-comp-cases .img-wrapper {
  box-shadow: -1.2px 4.9px 10px 0 rgba(0, 0, 0, 0.3);
}
.a-comp-cases .img-wrapper::after {
  display: none;
}
.a-comp-cases-leaflet {
  padding-bottom: 15px;
}
.corp-cult-hero .container {
  padding-bottom: 65px;
}
.corp-cult-hero .hero__qoute {
  font-style: italic;
  margin: 10px 0;
}
.corp-cult-hero .hero__text {
  margin-top: 20px;
}
.corp-cult-links {
  padding: 40px 0;
  border: solid 1px #d1cfcf;
}
.corp-cult-links .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.corp-cult-links .btn {
  padding: 0 10px 0 15px;
  font-size: 20px;
  line-height: 35px;
  margin-right: 110px;
}
.corp-cult-links p {
  font-size: 18px;
  line-height: 1;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .corp-cult-links p {
    width: auto;
  }
}
.corp-cult-links p a {
  display: inline-block;
  margin-left: 5px;
  position: relative;
}
.corp-cult-links p a::after {
  content: "";
  position: absolute;
  right: -45px;
  top: -14px;
  width: 35px;
  height: 35px;
  background: transparent url(../img/icons/bag-icon.png) center / cover no-repeat;
}
.corp-cult-links p a::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #c14624;
  transition: all 0.3s linear;
}
.corp-cult-links p a:hover::before {
  background-color: rgba(255, 255, 255, 0);
}
@media screen and (max-width: 992px) {
  .corp-cult-links .btn {
    margin-right: 60px;
  }
}
@media screen and (max-width: 768px) {
  .corp-cult-links .container {
    flex-direction: column;
    align-items: center;
  }
  .corp-cult-links .btn {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .corp-cult-links p {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .corp-cult-links p a {
    margin-top: 20px;
  }
}
.corp-cult-cases {
  background-color: #f0f0f0;
}
.corp-cult-cases__title {
  text-align: center;
  margin-bottom: 40px;
}
.corp-cult-cases__title span {
  text-transform: none;
}
.corp-cult-cases .container {
  position: relative;
}
.corp-cult-cases .container::before,
.corp-cult-cases .container::after {
  content: "";
  position: absolute;
  width: 824px;
  height: 42px;
  bottom: -42px;
  background-image: url(../img/header/header-shadow-left.png);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
.corp-cult-cases .container::before {
  left: -120px;
}
.corp-cult-cases .container::after {
  right: -120px;
  transform: scaleX(-1);
}
.corp-cult-cases .container .img-wrapper {
  box-shadow: -1.2px 4.9px 10px 0 rgba(0, 0, 0, 0.3);
}
.corp-cult-cases .container .img-wrapper::after {
  display: none;
}
.corp-cult-cases .s-case__item .wrap {
  padding-bottom: 45px;
}
.corp-cult-cases.s-case .custom-arrow {
  top: 41%;
}
.corp-cult-cases .corp-cult-carousel-m {
  display: none;
}
@media screen and (max-width: 1400px) {
  .corp-cult-cases .container::before {
    left: 0;
  }
  .corp-cult-cases .container::after {
    right: 0;
  }
}
@media screen and (max-width: 1200px) {
  .corp-cult-cases .container::before,
  .corp-cult-cases .container::after {
    display: none;
  }
  .corp-cult-cases .corp-cult-carousel {
    display: none;
  }
  .corp-cult-cases .corp-cult-carousel-m {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .s-case__text {
    font-size: 14px;
  }
  .corp-cult-cases .s-case__item .wrap {
    padding-bottom: 25px;
  }
}
.inner-slider {
  width: 100%;
  margin: auto;
  margin-top: 30px;
}
.inner-slider .owl-item {
  display: flex;
  justify-content: center;
}
.inner-slider .owl-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}
.inner-slider .owl-dot {
  padding: 0 10px;
  margin: 10px;
}
.inner-slider.owl-carousel .owl-item .img-wrapper {
  width: auto;
  max-width: 870px;
  max-height: 400px;
}
.inner-slider.owl-carousel button.owl-dot {
  max-width: 100px !important;
  height: auto !important;
  max-height: 90px !important;
}
.inner-slider.owl-carousel button.owl-dot.active {
  transform: scale(1.2);
}
.inner-slider.owl-carousel button.owl-dot img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.inn__text {
  margin-top: 30px;
}
.inn__title {
  text-align: center;
  margin-bottom: 15px;
}
.inn__category {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.inn__cat-item {
  margin-right: 10px;
}
@media screen and (max-width: 900px) {
  .inner-slider.owl-carousel .owl-item .img-wrapper {
    max-width: 588px;
    max-height: 300px;
  }
}
@media screen and (max-width: 640px) {
  .inner-slider.owl-carousel .owl-item .img-wrapper {
    max-width: 300px;
    max-height: 242px;
  }
  .inner-slider.owl-carousel button.owl-dot {
    max-width: 60px !important;
    max-height: 50px !important;
  }
}
/* text-based popup styling */
.white-popup {
  position: relative;
  background: #FFF;
  padding: 35px;
  width: auto;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
/* 

====== Zoom effect ======

*/
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
.g-recaptcha > div,
.g-recaptcha iframe {
  margin-bottom: 16px;
}
.re-wrap {
  position: relative;
  padding-bottom: 20px;
}
.re-error {
  display: none;
  position: absolute;
  bottom: 5px;
  left: 0;
  color: #ca0f0f;
}
