.contacts {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding: 35px 0 25px;
  .contacts-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 23px;
  }
  &__top-text,
  &__top-subtext {
    font-size: 24px;
  }
  &__top-subtext {
    color: #363636;
  }
  hr {
    margin: 25px 0 35px;
    max-width: 514px;
    width: 100%;
  }
  &__item {
    font-weight: normal;
    display: block;
    font-size: 24px;
    line-height: 65px;
  }
  &__item-text {
    color: #363636;
    margin-left: 5px;
  }
  .contacts-form {
    max-width: 342px;
    width: 100%;
    text-align: center;
    &__title {
      margin-bottom: 15px;
    }
    textarea {
      margin-bottom: 10px;
    }
    &__btn {
      line-height: 48px;
      padding: 0 65px 0 70px;
    }
  }
  .c-where {
    margin-top: 20px;
    max-width: 700px;
    &__title {
      margin-bottom: 15px;
    }
    &__text {
      margin-bottom: 10px;
    }
  }
}
@media screen and  (max-width: @screen-lg) {
  .contacts {
    .contacts-wrap {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-right: 0;
    }
    hr {
      margin: 10px 0;
    }
    &__item {
      line-height: 1.6;
    }
    .contacts-form {
      margin-top: 25px;
    }
  }
}
@media screen and  (max-width: 600px) {
  .contacts {
    &__item-text {
      font-size: 18px;
    }
  }
}
@media screen and  (max-width: @screen-sm) {
  .contacts {
    &__top-subtext {
      font-size: 18px;
    }
    &__item {
      font-size: 17px;
    }
  }
}