.a-comp-cases {
  padding-top: 30px;
  .s-case {
    &__title {
      span {
        text-transform: none;
      }
    }
    &__item {
      padding-bottom: 0;
    }
  }
  .img-wrapper {
    box-shadow: -1.2px 4.9px 10px 0 rgba(0, 0, 0, 0.3);
    &::after {
      display: none;
    }
  }
}