.corp-cult-links {
  padding: 40px 0;
  border: solid 1px #d1cfcf;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    padding: 0 10px 0 15px;
    font-size: 20px;
    line-height: 35px;
    margin-right: 110px;
  }
  p {
    font-size: 18px;
    line-height: 1;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
    }
    a {
      display: inline-block;
      margin-left: 5px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: -45px;
        top: -14px;
        width: 35px;
        height: 35px;
        background: transparent url(../img/icons/bag-icon.png) center/cover no-repeat;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: @color-main;
        .transition();
      }
      &:hover {
        &::before {
          background-color: rgba(255,255,255,0);
        }
      }
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .corp-cult-links {
    .btn {
      margin-right: 60px;
    }
  }
}
@media screen and (max-width: @screen-md) {
  .corp-cult-links {
    .container {
      flex-direction: column;
      align-items: center;
    }
    .btn {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .corp-cult-links {
    p {
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        margin-top: 20px;
      }
    }
  }
}