.article {
  background-color: #f0f0f0;
  .container {
    padding-top: 25px;
    padding-bottom: 150px;
  }
  .article-top {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .img-wrapper {
    max-width: 373px;
    max-height: 254px;
  }
  &__title {
    margin-left: 33px;
    max-width: 438px;
    color: #363636;
    font-size: 36px;
    line-height: 48px;
    text-transform: none;
  }
  &__text {
    text-align: justify;
    &:nth-child(even) {
      margin-bottom: 30px;
    }
  }
  .article-pag {
    display: flex;
    justify-content: center;
    color: #c14624;
    font-size: 18px;
    line-height: 1;
    font-style: italic;
    &__prev {
      margin-right: 79px;
    }
  }
}
@media screen and (max-width: @screen-md) {
  .article {
    .container {
      padding-bottom: 40px;
    }
    .article-top {
      flex-direction: column;
      align-items: center;
    }
    &__title {
      max-width: none;
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .article {
    &__title {
      text-align: center;
      line-height: 1.2;
    }
    &__text {
      line-height: 1.2;
    }
    .article-pag {
      flex-direction: column;
      align-items: center;
      &__prev {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }
}