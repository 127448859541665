.request {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 25px;
  &__img-wrapper {
    align-self: flex-end;
    height: 473px;
    min-width: 311px;
  }
  &__inner-text {
    margin-top: 25px;
    font-size: 24px;
    color: #393939;
    line-height: 1.7;
    margin-left: -44px;
  }
  &__big-text {
    font-size: 32.5px;
    text-transform: uppercase;
    color: #363636;
  }
  &__text {
    &:last-child {
      color: #3d3d3d;
    }
  }
}
.request-block {
  display: flex;
  justify-content: space-between;
}
.form-request {
  position: relative;
  z-index: 1;
  width: 342px;
  margin-top: 25px;
  .btn {
    margin-bottom: 20px;
  }
  textarea {
    height: 168px;
  }
  button {
    font-size: 18px;
    line-height: 48px;
    padding: 0 64px 0 71px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &:hover {
      color: #fff;
      //border-color: rgba(255,255,255,1);
    }
  }
}
@media screen and (max-width: @screen-xl) {
  .request {
    &__inner-text {
      line-height: 1.5;
      font-size: 20px;
    }
    &__big-text {
      font-size: 22px;
    }
  }
}
@media screen and (max-width: @screen-lg) {
  .request-block {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .request {
    &__inner-text {
      order: -1;
      width: 100%;
      margin-left: 0;
      text-align: center;
    }
  }
}
@media screen and (max-width: 700px) {
  .request {
    padding-bottom: 30px;
    .request-block {
      flex-direction: column;
      align-items: center;
    }
    &__inner-text {
      margin-top: 0;
    }
    &__img-wrapper {
      align-self: auto;
      width: 250px;
      height: 380px;
      // min-width: auto;
      min-width: 250px;
    }
    .form-request {
      margin-top: 0;
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .request {
    .form-request {
      width: 100%;
    }
  }
}