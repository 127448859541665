.why {
  padding: 65px 0 57px;
  text-align: center;
  background-color: #f0f0f0;
  &__title {
    font-size: 31px;
    line-height: 1;
    color: #bf4625;
    margin-bottom: 5px;
  }
  &__subtitle {
    font-size: 24px;
    line-height: 31px;
    color: #000;
    text-transform: none;
  }
  &__interest {
    padding: 0 26px 0 33px;
    line-height: 48px;
  }
}
.why-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 60px;
}
.why-noactual {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__title {
    font-size: 20px;
    line-height: 31px;
    color: #df0024;
    display: inline-block;
    position: relative;
    padding-left: 50px;
    margin-bottom: 25px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 34px;
      height: 55px;
      background: transparent url(../img/why/why-noactual-icon.png) center/cover no-repeat;
    }
  }
}
.why-actual {
  &__title {
    display: inline-block;
    position: relative;
    padding-left: 50px;
    margin-bottom: 25px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 46px;
      height: 57px;
      background: transparent url(../img/why/why-actual-icon.png) center/cover no-repeat;
    }
  }
  &__list {
    text-align: left;
    padding: 0 35px 15px 35px;
    position: relative;
    border-bottom: 1px solid #dddddd;
  }
  &__item {
    font-size: 20px;
    line-height: 50px;
    position: relative;
    padding-left: 28px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 16px;
      width: 18px;
      height: 17px;
      background: transparent url(../img/why/star-icon.png) center/cover no-repeat;
    }
  }
}
.why-links {
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
  margin: 35px 0 25px;
  a {
    color: #c24927;
    line-height: 1;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__our {
    &::before {
      width: 35px;
      height: 35px;
      background-image: url(../img/why/why-case-icon.png);
      left: -48px;
      bottom: -5px;
    }
  }
  &__strategy {
    &::before {
      width: 51px;
      height: 49px;
      background-image: url(../img/why/monitor-icon.png);
      left: -54px;
      bottom: -10px;
    }
  }
}
@media screen and (max-width: @screen-xl) {
  .why-noactual {
    img {
      max-width: 420px;
      max-height: 230px;
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-width: @screen-lg) {
  .why-block {
    flex-direction: column;
    align-items: center;
  }
  .why-noactual {
    margin-bottom: 60px;
  }
  .why-actual {
    &__list {
      padding: 0;
    }
    &__item {
      line-height: 1.5;
      padding: 10px 0 10px 28px;
    }
  }
  .why-links {
    padding-right: 0;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .why-noactual {
    display: none;
  }
  .why {
    &__title {
      span {
        display: block;
      }
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .why {
    padding: 40px 0;
  }
}
@media screen and (max-width: 330px) {
  .why-links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__our {
      margin-bottom: 30px;
    }
  }
}