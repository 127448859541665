.reviews {
  background-color: #f0f0f0;
  &__title {
    color: #363636;
    text-transform: none;
    padding: 30px 0;
  }
  &__name {
    position: relative;
    background-color: #f0f0f0;
    display: block;
    padding-right: 9px;
    font-size: 22px;
    line-height: 1;
    font-style: normal;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-right: auto;
    }
  }
  &__time {
    position: relative;
    background-color: #f0f0f0;
    padding-left: 40px;
    font-size: 16px;
    line-height: 22px;
    &::before {
      content: '';
      position: absolute;
      left: 5px;
      top: 0;
      background: transparent url(../img/icons/note-icon.png);
      width: 30px;
      height: 28px;
    }
  }
  &__rate {
    font-size: 18px;
    line-height: 22px;
    color: #363636;
    padding-left: 33px;
    position: relative;
    margin-top: 5px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 40%;
      transform: translateY(-50%);
      width: 28px;
      height: 26px;
      background: transparent url(../img/icons/star-icon.png) center/cover no-repeat;
    }
  }
  &__rate-num {
    color: #c14624;
    margin-left: 5px;
  }
  &__text-block {
    margin: 15px 0 10px;
    padding-left: 43px;
    padding-right: 40px;
    text-align: justify;
    line-height: 1.22;
    // overflow: hidden;
    // height: 50px;
    &.oh {
      overflow: hidden;
      height: 45px;
    }
  }
  &__read-more {
    color: #c14624;
    font-size: 16px;
    line-height: 22px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
  }
  .reviews-block {
    padding: 20px 0 20px 0;
    &:nth-child(odd) {
      background-color: #fff;
      .reviews__name,
      .reviews__time {
        background-color: #fff;
      }
    }
  }
  .reviews-header {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    color: #c14624;
    padding: 10px 0;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: -100vw;
      top: 28px;
      height: 1px;
      background-color: #c14624;
    }
  }
}
@media screen and  (max-width: @screen-md) {
  .reviews {
    &__title {
      text-align: center;
    }
    .reviews-header {
      flex-direction: column;
      align-items: center;
      &::before {
        display: none;
      }
    }
    &__text-block {
      padding: 0;
    }
    &__name {
      padding-right: 0;
      margin-bottom: 15px;
    }
    &__rate {
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}