/* pt-sans-regular - latin_cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local('PT Sans'), local('PTSans-Regular'),
  url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/ptsans/pt-sans-v9-latin_cyrillic-regular.svg#PTSans') format('svg'); /* Legacy iOS */
}
//@import url('https://fonts.googleapis.com/css?family=PT+Sans');
/*  HelveticaNeueCyr-Roman - cyrillic */
@font-face {
  font-family: 'HelveticaNeueCyr-Roman';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/helvetica/HelveticaRegular.eot'); /* IE9 Compat Modes */
  src: local('HelveticaRegular'), local('HelveticaNeueCyr-Roman'),
  url('../fonts/helvetica/HelveticaRegular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/helvetica/HelveticaRegular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/helvetica/HelveticaRegular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/helvetica/HelveticaRegular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/helvetica/HelveticaRegular.svg#HelveticaNeueCyr-Roman') format('svg'); /* Legacy iOS */
}
/*  HeliosCondLightNormal - cyrillic */
@font-face {
  font-family: 'HeliosCondLightNormal';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/helios/HeliosCondLightNormal.eot'); /* IE9 Compat Modes */
  src: local('HeliosCondLightNormal'), local('HeliosCondLightNormal'),
  url('../fonts/helios/HeliosCondLightNormal.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/helios/HeliosCondLightNormal.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/helios/HeliosCondLightNormal.woff') format('woff'), /* Modern Browsers */
  url('../fonts/helios/HeliosCondLightNormal.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/helios/HeliosCondLightNormal.svg#HeliosCondLightNormal') format('svg'); /* Legacy iOS */
}