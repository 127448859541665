.extra-header {
  box-shadow: 2.5px 4.3px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  .container {
    &::after,
    &::before {
      display: none;
    }
  }
  &.extra-header-main {
    .container {
      &::after,
      &::before {
        //display: block;
      }
    }
  }
}
.extra-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__item {
    position: relative;
    padding: 18px 0 18px 35px;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:nth-child(1) {
      &::before {
        width: 24px;
        height: 24px;
        background-image: url(../img/header/strategy-icon.png);
      }
    }
    &:nth-child(2) {
      &::before {
        width: 30px;
        height: 26px;
        background-image: url(../img/header/sites-icon.png);
      }
    }
    &:nth-child(3) {
      &::before {
        width: 28px;
        height: 27px;
        background-image: url(../img/header/selling-icon.png);
      }
    }
    &:nth-child(4) {
      &::before {
        width: 29px;
        height: 24px;
        background-image: url(../img/header/ad-companies-icon.png);
      }
    }
    &:nth-child(5) {
      &::before {
        width: 28px;
        height: 30px;
        background-image: url(../img/header/corp-culture-icon.png);
      }
    }
  }
  &__link {
    font-size: 20px;
    line-height: 1;
    color: #c24725;
    display: inline-block;
  }
}
@media screen and (max-width: @screen-lg) {
  .extra-header {

  }
  .extra-nav {
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 64px;
    padding: 10px 0;
    &__item {
      padding-top: 0;
      padding-bottom: 0;
      margin: 5px 0;
    }
    &__link {
      font-size: 18px;
    }
  }
}