.diplomas {
  padding: 45px 0 100px;
  &__title {
    text-transform: none;
    font-weight: normal;
    text-align: center;
    margin-bottom: 40px;
  }
}

.diplomas-carousel {
  .owl-stage {
    display: flex;
    align-items: center;
  }
  .owl-item {
    text-align: center;
    img {
      // width: auto;
      box-shadow: -1px 3.9px 5px 0 rgba(0, 0, 0, 0.39);
      // margin: auto;
    }
  }
  .img-wrapper {
    max-width: 335px;
    max-height: 337px;
  }
}

@media screen and (max-width: @screen-md) {
  .diplomas {
    padding: 40px 0;
    .d-c-h {
      // width: 166px;
      // height: 236px;
    }
    .d-c-w {
      // width: 236px;
      // height: 166px;
    }
    .img-wrapper {
      max-width: 170px;
      max-height: 237px;
    }
  }
}