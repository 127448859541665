.sites-hero {
  .container {
    padding-bottom: 65px;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  &__text {
    width: 46% !important;
    font-size: 18px;
    line-height: 22px;
  }
}
@media screen and (max-width: @screen-md) {
  .sites-hero {
    .container {
      padding-bottom: 40px;
    }
    &__wrap {
      display: block;
    }
    &__text {
      width: auto !important;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}