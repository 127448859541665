.inner-slider {
  width: 100%;
  margin: auto;
  margin-top: 30px;
  .owl-item {
    display: flex;
    justify-content: center;
  }
  img {
    // width: 100%;
  }
  .owl-stage-outer {
    // border: 5px solid;
  }
  .owl-dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
  .owl-dot {
    padding: 0 10px;
    margin: 10px;
  }
  &.owl-carousel .owl-item .img-wrapper {
    width: auto;
    max-width: 870px;
    max-height: 400px;
  } 
  &.owl-carousel button.owl-dot {
    max-width: 100px !important;
    height: auto !important;
    max-height: 90px !important;
    &.active {
      transform: scale(1.2);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.inn {
  &__text {
    margin-top: 30px;
  }
  &__title {
    text-align: center;
    margin-bottom: 15px;
  }
  &__category {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  &__cat-item {
    margin-right: 10px;
  }
}
@media screen and (max-width: 900px) {
  .inner-slider.owl-carousel .owl-item .img-wrapper {
    max-width: 588px;
    max-height: 300px;
  }
}
@media screen and (max-width: 640px) {
  .inner-slider.owl-carousel .owl-item .img-wrapper {
    max-width: 300px;
    max-height: 242px;
  }
  .inner-slider.owl-carousel button.owl-dot {
    max-width: 60px !important;
    max-height: 50px !important;
  }
}