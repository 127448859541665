.strategy-cases {
  &__title {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 65px;
  }
  .s-strategy-carousel {
    .owl-stage {
      // display: flex;
      // justify-content: center;
      // flex-wrap: wrap;
    }
    .owl-item {
      // width: 30% !important;
    }
  }
  .s-case__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 0;
    .wrap {
      width: 30%;
      padding-bottom: 64px;
    }
  }
  &.s-case .custom-arrow {
    top: 43%;
  }
  .s-strategy-carousel-m {
    display: none;
  }
}


@media screen and (max-width: @screen-xl) {
  .strategy-cases {
    .s-strategy-carousel {
      display: none;
    }
    .s-strategy-carousel-m {
      display: block;
    }
    .s-case__item {
      justify-content: center;
      .wrap {
        width: auto;
      }
    }
  }
}
@media screen and (max-width: @screen-md) {
  .strategy-cases {
    &__title {
      margin-bottom: 20px;
    }
    .s-case {
      &__item {
        .wrap {
          padding-bottom: 48px;
        }
      }
    }
  }
  .s-case {
    .img-wrapper {
      width: 256px;
      height: 192px;
    }
  }
}