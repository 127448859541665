.a-links-s {
  background-color: #e0e0e0;
  //overflow: hidden;
  //box-shadow: 2.5px 4.3px 5px 0 rgba(0,0,0,.3);
  position: relative;
  .container {
    padding: 55px 0 52px;
  }
  .container {
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 824px;
      height: 42px;
      bottom: -42px;
      background-image: url(../img/header/header-shadow-left.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
    &::before {
      left: -120px;
      @media screen and (max-width: 1430px) {
        left: -25px;
        width: 734px;
      }
    }
    &::after {
      right: -120px;
      transform: scaleX(-1);
      @media screen and (max-width: 1650px) {
        right: -120px;
      }
      @media screen and (max-width: 1430px) {
        right: 0;
        width: 734px;
      }
    }
  }
  &__btn {
    margin-right: 130px;
    font-size: 20px;
  }
  &__link {
    position: relative;
    color: #bf4624;
    font-size: 20px;
    line-height: 1;
    //.transition();
    &::before,
    &::after {
      content: '';
      position: absolute;
      .transition();
    }
    &::before {
      width: 21px;
      height: 21px;
      background: transparent url(../img/icons/wheel-icon--red.png) center/cover no-repeat;
      top: 50%;
      transform: translateY(-50%);
      left: -28px;
    }
    &::after {
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #bf4624;
    }
  }
  .a-links-s-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.a-links-s-services {

}
@media screen and (max-width: 1180px) {
  .a-links-s {
    .container {
      box-shadow: 0 5px 9px rgba(0,0,0,.26);
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
@media screen and (max-width: @screen-md) {
  .a-links-s {
    .container {
      padding: 30px;
    }
  }
  .a-links-s-block {
    flex-direction: column;
  }
  .a-links-s {
    &__btn {
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 600px) {
  .a-links-s {
    &__btn {
      margin-right: 0;
      padding: 0 30px;
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .a-links-s {
    &__link {
      font-size: 16px;
    }
  }
}