.s-hero {
  &__title {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 5px;
    &:last-child {
      margin-top: 20px;
    }
  }
}