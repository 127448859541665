.sites-cases {
  padding-top: 30px;
  .sites-cases-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__title {
    text-align: center;
    text-transform: none;
    margin-bottom: 25px;
  }
  &__item {
    width: 45%;
    text-align: center;
    margin-bottom: 29px;
    // height: 367px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .img-wrapper {
      border-radius: 5px;
      // max-width: 498px;
      // max-height: 352px;
      width: 498px;
      height: 352px;
    }
  }
  &__name {
    color: #c14624;
    border-bottom: 1px solid #c14624;
    line-height: 1;
    display: inline-block;
    position: relative;
    // transform: translateY(-5px);
    margin-top: 10px;
    .transition();
    &:hover {
      border-color: rgba(255,255,255,0);
      color: #434343
    }
  }
  &__text {
    color: #494949;
    line-height: 1;
    position: relative;
    // transform: translateY(-5px);
  }
}
@media screen and (max-width: @screen-xl) {
  .sites-cases__item .img-wrapper {
    width: 400px;
    height: 248px;
  }
}
@media screen and (max-width: @screen-lg) {
  .sites-cases__item .img-wrapper {
    width: 300px;
    height: 189px;
  }
}
@media screen and (max-width: @screen-md) {
  .sites-cases {
    .sites-cases-block {
      flex-direction: column;
      align-items: center;
    }
    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 35px;
      .img-wrapper {
        margin-bottom: 5px;
      }
    }
    &__name {
      transform: none;
    }
    &__text {
      transform: none;
    }
  }
}
@media screen and (max-width: @screen-md) {
  .sites-cases {
    &__name,
    &__text {
      font-size: 16px;
    }
  }
}