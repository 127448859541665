.partners {
  //overflow: hidden;
  .container {
    position: relative;
    padding: 100px 0 80px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: -57px;
      height: 57px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &::before {
      left: 0;
      width: 630px;
      background-image: url(../img/partners/partners-shadow-left.png);
    }
    &::after {
      right: 0;
      width: 657px;
      background-image: url(../img/partners/partners-shadow-right.png);
    }
  }
  &__title {
    text-align: center;
    color: #fff;
    background-color: #a5b0ba;
    line-height: 80px;
  }
  &__carousel {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    position: relative;
    .owl-stage {
      display: flex;
      align-items: center;
    }
    .owl-item {
      text-align: center;
      .img-wrapper {
        // max-width: 167px;
        // max-height: 115px;
        max-width: 120px;
        max-height: 120px;
        // max-height: 300px;
        // max-width: 300px;
      }
      // img {
      //   width: auto;
      //   margin: auto;
      // }
      
    }
  }
  .owl-carousel.owl-loaded {
    display: flex;
  }
}
@media screen and (max-width: @screen-xl) {
  .partners {
    //overflow: hidden;
    .container {
      box-shadow: 2.5px 4.3px 5px 0 rgba(0,0,0,.1);
      &::before,
      &::after {
        display: none;
      }
    }
    &__carousel {
      padding: 0 40px;
    }
  }
}
@media screen and (max-width: @screen-lg) {
  .partners {
    .container {
      padding: 40px 0;
    }
  }
}