.test {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 35px 0 19px;
  &__title {
    text-align: center;
    font-size: 31px;
    margin-bottom: 5px;
  }
  &__subtitle {
    font-size: 24px;
    color: #3c3c3c;
    text-align: center;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  &__what {
    width: 315px;
    height: 315px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 2.5px 4.3px 5px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 35px;
    margin-top: 15px;
  }
  &__what-title {
    font-size: 31px;
    line-height: 36px;
    color: #3c3c3c;
    margin-bottom: 30px;
  }
  &__what-text {
    line-height: 1;
    color: #525252;
    .bottom {
      display: none;
    }
  }
  &__list {
    color: #434343;
    display: flex;
    flex-direction: column;
    position: relative;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-size: cover;
      width: 43px;
      height: 88px;
      background-image: url(../img/test/test-arrow.png);
    }
    &::before {
      left: -70px;
    }
    &::after {
      right: -70px;
    }
  }
  &__item {
    font-size: 20px;
    line-height: 2;
    position: relative;
    cursor: pointer;
    padding-left: 33px;
    padding-right: 10px;
    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 17px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #6a6a6a;
      .transition();
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: rgb(193, 70, 36);
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
    &.active {
      list-style-type: none;
      color: #fff;
      &::before {
        background-color: rgba(255,255,255,0);
      }
      &::after {
        height: 100%;
      }
      .test__item-text {
        &::after {
          display: none;
        }
      }
    }
  }
  &__item-text {
    position: relative;
    display: inline-block;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 8px;
      height: 1px;
      background-color: #434343;
      .transition();
    }
    &:hover {
      &::after {
        background-color: rgba(255,255,255,0);
      }
    }
  }
  &__answer {
    height: 315px;
    margin-top: 15px;
  }
  &__answer-main {
    box-shadow: 2.5px 4.3px 18px 0 rgba(0, 0, 0, 0.75);
    border-radius: 50%;
    overflow: hidden;
    width: 315px;
    height: 315px;
    position: relative;
    border: 3px solid rgb(255,255,255);
    &:not(:first-child) {
      display: none;
    }
  }
  &__answer-inner {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba(255,255,255,0.62);
    img {
      width: auto;
      height: auto;
    }
  }
  &__answer-icon {
    display: inline-block;
    vertical-align: middle;
  }
  &__answer-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 52px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
    }
  }
  &__share {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    flex-wrap: wrap;
  }
  &__share-text {
    font-size: 18px;
    line-height: 1;
    color: #3d4b5b;
    margin-right: 16px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
    }
  }
}
@media screen and (max-width: @screen-xl) {
  .test {
    &__content {
      align-items: center;
    }
    &__list {
      &::before,
      &::after {
        width: 25px;
        height: 53px;
      }
      &::before {
        left: -45px;
      }
      &::after {
        right: -45px;
      }
    }
    &__what,
    &__answer {
      width: 250px;
      height: 250px;
      margin-top: 0;
    }
    &__what-title {
      margin-bottom: 15px;
      font-size: 26px;
      line-height: 1.2;
    }
    &__answer-main {
      width: 250px;
      height: 250px;
    }
  }
}
@media screen and (max-width: @screen-lg) {
  .test {
    &__content {
      flex-wrap: wrap;
      justify-content: center;
    }
    &__what {
      width: 100%;
      height: auto;
      background: none;
      box-shadow: none;
      margin-bottom: 20px;
    }
    &__what-text {
      br {
        display: none;
      }
    }
    &__list {
      margin-right: 15px;
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .test {
    &__title {
      span {
        display: none;
      }
    }
    &__share-text {
      font-size: 16px;
      margin-right: 5px;
    }
    &__content {
      flex-direction: column;
    }
    &__what-text {
      .right {
        display: none;
      }
      .bottom {
        display: inline;
      }
    }
    &__list {
      margin-bottom: 20px;
      margin-right: 0;
    }
    &__item {
      font-size: 18px;
    }
    &__share {
      margin-top: 25px;
    }
  }
}
@media screen and (max-width: 400px) {
  .test__share {
    flex-direction: column;
  }
  .test__share-text {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .ya-share2__container_size_m li.ya-share2__item {
    margin-left: 15px;
  }
}