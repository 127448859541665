.s-case {
  padding: 35px 0 0 0;
  &.s-selling,
  &.s-cult {
    background-color: #f0f0f0;
  }
  .custom-arrow {
    top: 40%;
  }
  &__title {
    text-align: center;
    margin-bottom: 25px;
  }
  &__subtitle {
    text-align: center;
    margin-bottom: 30px;
  }
  &__text {
    font-size: 16px;
    line-height: 18px;
    color: @color-main;
    padding: 0 33px;
    margin-top: 5px;
  }
  &__item {
    padding-bottom: 64px;
    text-align: center;
    position: relative;
  }
  .img-wrapper {
    width: 321px;
    height: 241px;
    border: solid 1px #aeaeae;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -58px;
      width: 299px;
      height: 64px;
      background: transparent url(../img/s-case/s-case-shadow.png) center/cover no-repeat;
      z-index: -1;
    }
    img {
      height: 100%;
    }
  }
  &__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(71, 91, 130, 0.8);
    color: #fff;
    opacity: 0;
    z-index: -1;
    .transition();
    img.s-case__inner-img {
      width: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      font-size: 14px;
      line-height: 28px;
    }
  }
  .img-wrapper {
    &:hover {
      .s-case__inner {
        opacity: 1;
        z-index: 1;
      }
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .s-case .img-wrapper {
    width: 256px;
    height: 192px;
  }
}