.a-partners {
  background-color: #f0f0f0;
  padding: 50px 0 42px;
  &__title {
    text-align: center;
    text-transform: none;
    margin-bottom: 45px;
  }
  &__item {
    position: relative;
    width: 344px;
    min-height: 589px;
    background-color: #fff;
    border: solid 1px #c5573c;
    margin: auto;
    margin-bottom: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    &::after {
      content: '';
      position: absolute;
      left: -30px;
      right: 0;
      bottom: -42px;
      width: 387px;
      height: 42px;
      background: transparent url(../img/a-partners/a-partners-shadow.png) center/cover no-repeat;
    }
  }
  &__top-text {
    color: #c04421;
    font-size: 16px;
    line-height: 22px;
    font-style: italic;
  }
  &__subtext {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    text-transform: uppercase;
    position: relative;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      height: 1px;
      background-color: #000;
    }
  }
  .owl-carousel .owl-item img {
    width: auto;
  }
}
.a-p-dizarti {
  padding-left: 25px;
  padding-right: 27px;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-top: 20px;
  }
  &__subtitle {
    color: #898989;
    line-height: 24px;
    margin-top: 5px;
  }
  &__main-text {
    font-size: 15px;
    line-height: 22px;
    margin-top: 25px;
    .accent {
      text-transform: uppercase;
    }
  }
  .a-partners__top-text {
    margin-top: 25px;
    margin-bottom: 30px;
  }
  &__b-t {
    font-weight: bold;
  }
}
.a-p-chantecler {
  .img-wrapper {
    width: 342px;
    overflow: hidden;
    margin-bottom: auto;
    position: relative;
    &::after {
      content: '';
      position: absolute;

    }
  }
  .a-partners__subtext {
    margin-bottom: auto;
  }
}
.a-p-itspb {
  &__top {
    margin-top: 20px;
    img.a-p-itspb__logo-img {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &__title {
    display: inline-block;
    vertical-align: middle;
    color: #35aee5;
    text-shadow: -0.5px 1.9px 2px rgba(0, 0, 0, 0.32);
    font-size: 50px;
    line-height: 60px;
    font-family: @helios;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  &__subtitle {
    color: #34ade5;
    font-family: @helios;
    font-size: 15.5px;
    line-height: 1;
    margin-bottom: 30px;
  }
  .a-partners__subtext {
    margin-bottom: 5px;
  }
  .a-partners__top-text {
    padding: 0 18px 0 30px;
    margin-bottom: 22px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding:  0 18px 0 10px;
    }
  }
}
@media screen and (max-width: @screen-md) {
  .a-partners {
    padding-top: 35px;
    padding-bottom: 0;
  }
}
@media screen and (max-width: @screen-sm) {
  .a-partners {
    &__item {
      width: 290px;
      &::after {
        width: 309px;
        height: 33px;
        bottom: -33px;
      }
    }
  }
  .a-p-chantecler .img-wrapper {
    width: 288px;
  }
  .a-partners .owl-carousel .owl-item .a-p-dizarti img {
    width: auto;
  }
  .a-partners .owl-carousel .owl-item img {
    width: 100%;
  }
  .a-partners .owl-carousel .owl-item .a-p-itspb img {
    width: auto;
  }
  .a-partners .owl-carousel .owl-item .a-p-itspb .img-wrapper img {
    width: 100%;
  }
}