.corp-cult-hero {
  .container {
    padding-bottom: 65px;
  }
  .hero {
    &__qoute {
      font-style: italic;
      margin: 10px 0;
    }
    &__text {
      margin-top: 20px;
    }
  }
}