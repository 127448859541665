.header {
  position: relative;
  //overflow: hidden;
  &.header-main-page {
    .header-m-p {
      display: block;
    }
    .header-inner {
      display: none;
    }
  }
  .header-m-p {
    display: none;
    padding-top: 35px;
  }
  .header-bg {
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 982px;
    height: 407px;
    //height: 424px;
  }
  .container {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 824px;
      height: 42px;
      left: -120px;
      bottom: -42px;
      background-image: url(../img/header/header-shadow-left.png);
      background-repeat: no-repeat;
      background-size: contain;
      @media screen and (max-width: 1430px) {
        left: -25px;
        width: 734px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      width: 824px;
      height: 42px;
      right: -232px;
      bottom: -42px;
      background-image: url(../img/header/header-shadow-left.png);
      background-repeat: no-repeat;
      background-size: contain;
      transform: scaleX(-1);
      @media screen and (max-width: 1650px) {
        right: -120px;
      }
      @media screen and (max-width: 1430px) {
        right: 0;
        width: 734px;
      }
    }
  }
}
.header-m-p {
  background-color: rgba(240,240,240,1);
  .container {
    height: 100%;
    min-height: 389px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .header-block {
    display: flex;
    align-items: flex-start;
  }
  .header-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 28px;
  }
  .main-nav {
    display: flex;
    flex-direction: column;
    &__item {
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
    &__link {
      display: inline-block;
      position: relative;
      line-height: 1;
      //border-bottom: 1px solid #464646;
      .transition();
      &::after {
        background-color: #000;
      }
      &:hover {
        //color: @color-main;
        //border-color: @color-main;
      }
    }
  }

  .header-bottom {
    text-align: center;
    font-size: 30px;
    line-height: 1.4em;
    color: #2c2c2c;
    margin-bottom: 15px;
    &__bold {
      text-transform: uppercase;
      color: #c14624;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-top: 50px;
    }
  }
  .contacts-block {
    margin-left: auto;
  }
}
.header-inner {
  background-color: #cd6b50;
  color: #fff;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 10px;
  }
  .header-logo {
    &__title {
      color: #fffbcc;
      font-size: 30px;
      letter-spacing: 1px;
    }
    &__subtitle {
      color: rgba(255, 255, 255, 0.53);
      font-size: 15px;
      line-height: 19.5px;
    }
  }
  .main-nav {
    font-size: 18px;
    line-height: 1.3;
    margin-left: 48px;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__link {
      &:not(:last-child) {
        margin-right: 25px;
      }
      &::after {
        background-color: #fff;
      }
    }
  }
  .header-contacts {
    display: flex;
    align-items: center;
    &__tel {
      color: #fffbcc;
      font-size: 26px;
      line-height: 40px;
      white-space: nowrap;
      margin-left: 10px;
      .transition();
      &:hover {
        color: #404040;
      }
    }
  }
  .btn {
    background-color: #e35557;
    border: solid 1px #f08c8c;
    margin-left: 23px;
    &:hover {
      background-color: #fff;
    }
  }
}
@media screen and (max-width: @screen-xl) {
  .header-m-p {
    //box-shadow: 2.5px 4.3px 5px 0 rgba(0,0,0,.1);
  }
  .header-inner {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
    .main-nav {
      margin-left: 20px;
    }
  }
}
@media screen and (max-width: 1180px) {
  .header {
    .container {
      box-shadow: 0 5px 9px rgba(0,0,0,.26);
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
@media screen and (max-width: @screen-lg) {
  .header {
    .header-bg {
      background: none !important;
      display: none;
    }
    .container {
      &::before,
      &::after {
        display: none;
      }
    }
  }
  .header-m-p {
    .container {
      min-height: 315px;
    }
    .header-block {
      justify-content: center;
    }
    .contacts-block {
      margin-left: 50px;
    }
    .header-bottom {
      &__bold {
        display: none;
      }
    }
  }
  .header-inner {
    .container {
      flex-wrap: wrap;
      justify-content: center;
    }
    .main-nav {
      order: 1;
      width: 100%;
      margin-left: 0;
      margin-top: 15px;
      margin-bottom: 10px;
      justify-content: center;
    }
    .header-contacts {
      margin-left: 20px;
    }
  }
}
@media screen and (max-width: 600px) {
  .header {
    .header-m-p {
      padding-top: 0;
      .container {
        padding: 0;
      }
      .header-block {
        flex-direction: column;
        align-items: center;
      }
      .header-logo {
        flex-direction: row;
        margin-right: 0;
        padding-top: 20px;
        padding-bottom: 10px;
        width: 100%;
        background-color: #e6e6e6;;
        img {
          width: 90px;
          height: 90px;
          margin-right: 10px;
        }
      }
      .main-nav {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 5px;
        margin-bottom: 10px;
        width: 100%;
        background-color: #e6e6e6;
        box-shadow: 0 5px 9px rgba(0,0,0,.26);
        &__item {
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
      .contacts-block {
        margin-left: 0;
      }
      .header-bottom {
        margin-top: 10px;
        font-size: 20px;
      }
    }
    .header-inner {
      .container {
        flex-direction: column;
      }
      .header-logo {
        text-align: center;
      }
      .header-contacts {
        margin-left: 0;
        &__tel {
          margin-left: 0;
        }
      }
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .header-inner {
    .container {
      padding-bottom: 0;
    }
    .main-nav {
      &__link {
        margin-bottom: 10px;
      }
    }
    .header-contacts {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__tel {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .btn {
      margin-left: 0;
    }
  }
}
